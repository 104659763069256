  export const SET_CASCADINGDROPDOWN_DATA_FROM_PREFERENCE = "SET_CASCADINGDROPDOWN_DATA_FROM_PREFERENCE";
export const INIT_CASCADING_DROPDOWN = 'INIT_CASCADING_DROPDOWN';
export const SET_EXISTING_FILTERDATA = "SET_EXISTING_FILTERDATA";
export const SET_SAP_COMPANY_CODES = "SET_SAP_COMPANY_CODES";
export const SET_SELECTED_SAP_COMPANY_CODES = "SET_SELECTED_SAP_COMPANY_CODES";
export const SET_SELECTED_SUB_REGION = "SET_SELECTED_SUB_REGION";

export const SET_SHOWFIELDSFLAG = 'SET_SHOWFIELDSFLAG';
export const SET_SELECTED_OPTIONS = "SET_SELECTED_OPTIONS";

export const SET_FISCAL_MONTHS = "SET_FISCAL_MONTHS";
export const SET_SELECTED_FISCAL_MONTHS = "SET_SELECTED_FISCAL_MONTHS";
export const SET_DEFAULT_FISCAL_MONTHS = "SET_DEFAULT_FISCAL_MONTHS";

export const SET_SELECTED_CURRENCY_TYPES = "SET_SELECTED_CURRENCY_TYPES";
export const SET_DEFAULT_CURRENCY_TYPES = "SET_DEFAULT_CURRENCY_TYPES";
export const SET_CURRENCY_TYPES = "SET_CURRENCY_TYPES";

export const SET_SELECTED_PAID_HEAD_COUNTS = "SET_SELECTED_PAID_HEAD_COUNTS";
export const SET_DEFAULT_PAID_HEAD_COUNTS = "SET_DEFAULT_PAID_HEAD_COUNTS";

export const SET_DISPLAY_OPTIONS = "SET_DISPLAY_OPTIONS";
export const SET_SELECTED_DISPLAY_OPTIONS = "SET_SELECTED_DISPLAY_OPTIONS";
export const SET_DEFAULT_DISPLAY_OPTIONS = "SET_DEFAULT_DISPLAY_OPTIONS";

export const UPDATE_SELECTED_OPTIONS = "UPDATE_SELECTED_OPTIONS";

export const SET_SUB_REGION = "SET_SUB_REGION";
export const SET_DEFAULT_SUB_REGION = "SET_DEFAULT_SUB_REGION";
export const SET_DEFAULT_SAP_COMPANY_CODES = "SET_DEFAULT_SAP_COMPANY_CODES";

export const SET_CLASS_FILTER_OPTIONS = "SET_CLASS_FILTER_OPTIONS";
export const SET_SELECTED_CLASS_FILTER_OPTIONS =
  "SET_SELECTED_CLASS_FILTER_OPTIONS";
export const ERROR_CLASS_FILTER_OPTIONS = "ERROR_CLASS_FILTER_OPTIONS";

export const SET_SUBCLASS_FILTER_OPTIONS = "SET_SUBCLASS_FILTER_OPTIONS";
export const SET_SELECTED_SUBCLASS_FILTER_OPTIONS =
  "SET_SELECTED_SUBCLASS_FILTER_OPTIONS";
export const ERROR_SUBCLASS_FILTER_OPTIONS = "ERROR_SUBCLASS_FILTER_OPTIONS";

export const SET_GROUPCLASS_FILTER_OPTIONS = "SET_GROUPCLASS_FILTER_OPTIONS";
export const SET_SELECTED_GROUPCLASS_FILTER_OPTIONS =
  "SET_SELECTED_GROUPCLASS_FILTER_OPTIONS";
export const ERROR_GROUPCLASS_FILTER_OPTIONS =
  "ERROR_GROUPCLASS_FILTER_OPTIONS";

export const SET_LINEITEM_FILTER_OPTIONS = "SET_LINEITEM_FILTER_OPTIONS";
export const SET_SELECTED_LINEITEM_FILTER_OPTIONS =
  "SET_SELECTED_LINEITEM_FILTER_OPTIONS";
export const ERROR_LINEITEM_FILTER_OPTIONS = "ERROR_LINEITEM_FILTER_OPTIONS";

export const SET_FINACCOUNT_FILTER_OPTIONS = "SET_FINACCOUNT_FILTER_OPTIONS";
export const SET_SELECTED_FINACCOUNT_FILTER_OPTIONS =
  "SET_SELECTED_FINACCOUNT_FILTER_OPTIONS";
export const ERROR_FINACCOUNT_FILTER_OPTIONS =
  "ERROR_FINACCOUNT_FILTER_OPTIONS";

export const SET_ORDER_FILTER_OPTIONS = "SET_ORDER_FILTER_OPTIONS";
export const SET_SELECTED_ORDER_FILTER_OPTIONS =
  "SET_SELECTED_ORDER_FILTER_OPTIONS";
export const ERROR_ORDER_FILTER_OPTIONS = "ERROR_ORDER_FILTER_OPTIONS";

export const SET_PROFITCENTER_FILTER_OPTIONS =
  "SET_PROFITCENTER_FILTER_OPTIONS";
export const SET_SELECTED_PROFITCENTER_FILTER_OPTIONS =
  "SET_SELECTED_PROFITCENTER_FILTER_OPTIONS";
export const ERROR_PROFITCENTER_FILTER_OPTIONS =
  "ERROR_PROFITCENTER_FILTER_OPTIONS";

export const SET_COSTCENTER_FILTER_OPTIONS = "SET_COSTCENTER_FILTER_OPTIONS";
export const SET_SELECTED_COSTCENTER_FILTER_OPTIONS =
  "SET_SELECTED_COSTCENTER_FILTER_OPTIONS";
export const ERROR_COSTCENTER_FILTER_OPTIONS =
  "ERROR_COSTCENTER_FILTER_OPTIONS";

export const SET_ORG_FILTER_OPTIONS = "SET_ORG_FILTER_OPTIONS";
export const SET_SELECTED_ORG_FILTER_OPTIONS =
  "SET_SELECTED_ORG_FILTER_OPTIONS";
export const ERROR_ORG_FILTER_OPTIONS = "ERROR_ORG_FILTER_OPTIONS";

export const SET_ORGSUMMARY_FILTER_OPTIONS = "SET_ORGSUMMARY_FILTER_OPTIONS";
export const SET_SELECTED_ORGSUMMARY_FILTER_OPTIONS =
  "SET_SELECTED_ORGSUMMARY_FILTER_OPTIONS";
export const ERROR_ORGSUMMARY_FILTER_OPTIONS =
  "ERROR_ORGSUMMARY_FILTER_OPTIONS";

export const INIT_CLASS_FILTER_OPTIONS = "INIT_CLASS_FILTER_OPTIONS";
export const INIT_SUBCLASS_FILTER_OPTIONS = "INIT_SUBCLASS_FILTER_OPTIONS";
export const INIT_GROUPCLASS_FILTER_OPTIONS = "INIT_GROUPCLASS_FILTER_OPTIONS";
export const INIT_LINEITEM_FILTER_OPTIONS = "INIT_LINEITEM_FILTER_OPTIONS";
export const INIT_FINACCOUNT_FILTER_OPTIONS = "INIT_FINACCOUNT_FILTER_OPTIONS";
export const INIT_ORDER_FILTER_OPTIONS = "INIT_ORDER_FILTER_OPTIONS";
export const INIT_PROFITCENTER_FILTER_OPTIONS = "INIT_PROFITCENTER_FILTER_OPTIONS";
export const INIT_COSTCENTER_FILTER_OPTIONS = "INIT_COSTCENTER_FILTER_OPTIONS";
export const INIT_ORG_FILTER_OPTIONS = "INIT_ORG_FILTER_OPTIONS";
export const INIT_ORGSUMMARY_FILTER_OPTIONS = "INIT_ORGSUMMARY_FILTER_OPTIONS";

export const SET_COMPANYREGION_OPTIONS = "SET_COMPANYREGION_OPTIONS";
export const SET_SELECTED_COMPANYREGION_OPTIONS = "SET_SELECTED_COMPANYREGION_OPTIONS";
export const ERROR_COMPANYREGION_OPTIONS = "ERROR_COMPANYREGION_OPTIONS";

export const SET_QTD_DATA = "SET_QTD_DATA";
export const SET_SELECTED_QTD_DATA = "SET_SELECTED_QTD_DATA";
export const ERROR_QTD_DATA = "ERROR_QTD_DATA";

export const SET_SORT_DATA = "SET_SORT_DATA";
export const SET_SELECTED_SORT_ITEMS = "SET_SELECTED_SORT_ITEMS";
export const SORT_TOGGLE_CHECKED = "SORT_TOGGLE_CHECKED";
export const SORT_SORT_CHECKED_NULL = "SORT_SORT_CHECKED_NULL";


export const SET_TD_TYPE = "SET_TD_TYPE";
export const  INIT_GRID_DATA = "INIT_GRID_DATA"

export const INIT_PREFERENCES = "INIT_PREFERENCES";
export const SET_PREFERENCES_DATA = "SET_PREFERENCES_DATA";
export const SET_SELECTED_PREFERENCES_DATA = "SET_SELECTED_PREFERENCES_DATA";
export const ERROR_PREFERENCES_DATA = "ERROR_PREFERENCES_DATA";

export const SET_TEXTBOX_VALUE = "SET_TEXTBOX_VALUE";
export const SET_TEXTBOX_VALUE1 = "SET_TEXTBOX_VALUE1";

export const SET_EXPAND_COLLAPSE_TYPE = "SET_EXPAND_COLLAPSE_TYPE";

export const SET_EXPORT_DATA = "SET_EXPORT_DATA";
export const ERROR_EXPORT_DATA = "SET_EXPORT_DATA";

export const SET_MERCURY_REFRESH_TIME_VALUE = "SET_MERCURY_REFRESH_TIME_VALUE";
export const SET_MERCURY_REFRESH_TIME_VALUE1 = "SET_MERCURY_REFRESH_TIME_VALUE1";
export const SET_SUB_REGION_COMPANY_CODES = "SET_SUB_REGION_COMPANY_CODES";
export const SET_FILTER_PREFERENCES = "SET_FILTER_PREFERENCES";

export const SET_SELECTED_FILTER_PREFERENCE = "SET_SELECTED_FILTER_PREFERENCE";
export const SET_SELECTED_FILTER_PREFERENCES_DATA = "SET_SELECTED_FILTER_PREFERENCES_DATA"
export const SET_DEFAULT_FILTER_PREFERENCE = "SET_DEFAULT_FILTER_PREFERENCE";
export const SET_IS_PREFERENCE_CHANGED = "SET_IS_PREFERENCE_CHANGED";




export const SET_CHECKBOX_VALUE = "SET_CHECKBOX_VALUE";

export const SET_EXPORT_BTN_DISABLE = "SET_EXPORT_BTN_DISABLE";


export const SET_EXPANDALL = "SET_EXPANDALL";

export const SET_GET_SEGMENTAL_DATA_QTD_API = "SET_GET_SEGMENTAL_DATA_QTD_API";

export const SET_GLOBAL_SPINNER = "SET_GLOBAL_SPINNER";
export const SET_FILTER_SPINNER = "SET_FILTER_SPINNER";
export const SET_DEFAULT_PREFERENCEC_STATUS = "SET_DEFAULT_PREFERENCEC_STATUS";


export const SET_PREFRENCE_LOAD = "SET_PREFRENCE_LOAD";
export const TOGGLE_CHK_EXCLUDE_DATA = "TOGGLE_CHK_EXCLUDE_DATA";
export const TOGGLE_CHK_EXCLUDE_ZERO_DATA = "TOGGLE_CHK_EXCLUDE_ZERO_DATA";
export const SET_FILTER_GRID_COL_DATA = "SET_FILTER_GRID_COL_DATA";
export const SET_HEADER_GRID_COL_DATA = "SET_HEADER_GRID_COL_DATA";




export const SET_COMMENTARY_STATUS = "SET_COMMENTARY_STATUS";
export const SET_PARENT_ID_AND_COMMENTS = "SET_PARENT_ID_AND_COMMENTS";
export const SET_USER_ADMINSTRATION_ICON_STATUS = "SET_USER_ADMINSTRATION_ICON_STATUS";
export const SET_ROLEID_STATUS = "SET_ROLEID_STATUS";
export const SET_CAN_VIEW_STATUS = "SET_CAN_VIEW_STATUS";
export const SET_CAN_ENTER_STATUS = "SET_CAN_ENTER_STATUS";
// Define your action types as constants
export const CLEAR_COMMENT_DATA = 'CLEAR_COMMENT_DATA';


// MCIO Starts here   ---------------------------------------------------------------------////////////

export const SET_MCIO_INTIAL = 'SET_MCIO_INTIAL';
export const SET_MCIO_FOOTER = 'SET_MCIO_FOOTER';
export const SET_MCIO_DATA = 'SET_MCIO_DATA';

export const GET_MCIO_USERDATA = 'GET_MCIO_USERDATA';
export const ERROR_MCIO = 'ERROR_MCIO';




// MCIO Starts here   ---------------------------------------------------------------------////////////







