import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, ModalBody } from 'react-bootstrap'
import ThemeToggle from './ColorTheme/ThemeToggle';
import { RootReducer } from '../Redux/Reducers/types';
import { useDispatch, useSelector } from "react-redux";
import { setUserAdminstrationIconAction, setCommentaryStatusAction, setRolIDAction} from '../Redux/Actions/fieldSortAction';
import { getToken, getUsername } from './Services/MsalService';
import axios from 'axios';
import Config from '../Config';
import SpinnerComponent from './Common/SpinnerComponent';
import { FiSettings } from 'react-icons/fi';
import { GrCube } from "react-icons/gr";
import { config } from '../authConfig';
import { FiKey } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
// import { CommentUrl } from '../Config';
//import { GrUserManager, GrUser, GrAttachment } from "react-icons/gr";

type Props = {
  loading: boolean;
  Commentchk: boolean;
  handlecommentchn: () => void;
  show: boolean;
  onHide: () => void;
}


const Settings = (props: Props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const fieldSort = useSelector(
    (state: RootReducer) => state.fieldSort
  );


  
  const CommentUrl = Config.CommentUrl;
  
  
  const setDarkMode = async () => {
    document.querySelector("body")?.setAttribute("data-theme", "dark");
    localStorage.setItem("selectedTheme", "dark");
    // await savesetting({ Commentarystatus: fieldSort.Commentarystatus, Theme: "dark" });
  };

  const setLightMode = async () => {
    document.querySelector("body")?.setAttribute("data-theme", "light");
    localStorage.setItem("selectedTheme", "light");
    // await savesetting({ Commentarystatus: fieldSort.Commentarystatus, Theme: "light" });
  };


  const selectedTheme = localStorage.getItem("selectedTheme");
  // console.log("the>>>:::",selectedTheme);
  const [Commentchk, setCommentchk] = useState(fieldSort.Commentarystatus);
  const [theme, setTheme] = useState(selectedTheme);
  const [showsettingserroralert, setShowSettingErrorAlert] = useState(false);

  const [UserAdministrationIcon, setUserAdministrationIcon] = useState(fieldSort.UserAdministrationIcon);




  const handleClick = async (e: any) => {
    setLoading(true);
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);

    const savePreference = {
      Commentarystatus: Commentchk,
      Theme: newTheme,
    };
    await savesetting(savePreference);
  }

  const openUrl = async (e: any) => {
   // window.open(CommentUrl, '_blank');
   window.open('/cubeadmin', '_blank');
  }

  const handlecommentchn = async (e: any) => {
    setLoading(true);
    const isChecked = e.target.checked;

    setCommentchk(isChecked);

    const savePreference = {
      Commentarystatus: isChecked,
      Theme: theme,
    };


    // console.log("commentarychecking>>>@@",savePreference.Commentarystatus);

    dispatch(await setCommentaryStatusAction(isChecked));

    try {
      await savesetting(savePreference);
    } catch (error) {
      console.error("Failed to save settings:", error);
    }
  };

  const savesetting = async (savePreference: any) => {
    try {
      savePreference.IsCommentaryOpt = savePreference.Commentarystatus;
      savePreference.userAlias = await getUsername();
      const response = await axios.post(
        `${Config.apiBaseAddress}${Config.apiSaveUserSettings}`,
        JSON.stringify(savePreference),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${await getToken()}`,
          },
        }
      );
      if (response.status === 200) {
        setLoading(false);
      } 
      // else {
      //   setLoading(false);
      //   setShowSettingErrorAlert(true);
      // }
    } catch (error) {
      setLoading(false);
      setShowSettingErrorAlert(true);
      console.error("Failed to fetch classes:", error);
    }
  };




  useEffect(() => {
    var commentobject: any = document.getElementsByClassName('commentary-row-icon');
    if (commentobject.length > 0) {
      for (var i = 0; i < commentobject.length; i++) {
        commentobject[i].classList.remove("hideC");
        commentobject[i].classList.remove("showC");
        commentobject[i].classList.add(fieldSort.Commentarystatus ? "showC" : "hideC");
      }
    }
    setCommentchk(fieldSort.Commentarystatus);

  }, [fieldSort.Commentarystatus])


  const getCommentaryUserAccess = async () => {
    try {
        const response = await axios.get(
            `${Config.apiBaseAddress}${Config.apiCommentaryUserAccess}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${await getToken()}`,
                },
            }
        );
       
        const responseData = response.data.userAdministrationIcon;
        if(responseData === true) {
          setUserAdministrationIcon(responseData);
        dispatch(await setUserAdminstrationIconAction(responseData));
        }
        dispatch(await setRolIDAction(response.data.roleID));
        
       
       
    } catch (error) {
        console.error("Failed to fetch user access details:", error);
    }
  };

 useEffect(() => {
        getCommentaryUserAccess();

    }, []);
// const CommentAccessparam = "LEGALENTITY";
// const [hasCommentAccess,sethasCommentAccess] = useState(false);

// const getCommentAccess = async () => {
//   try {
//     // setLoading(true);
//     const token = await getToken();
//     const response = await axios.get(
//       `${Config.apiBaseAddressSecondary}${Config.apiCommentAccess}`,
//       {
//         params: { param: CommentAccessparam },  
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     );

//     if (response.status === 200) {
//       sethasCommentAccess(response.data);
//       return response.data;
//   } 
//   } catch (error) {
//     console.error("Failed to fetch comment access details:",error);
//     return false;
//   }
// };

// const handleFikeyClick = async () => {
//   setLoading(true);  
//     const access = await getCommentAccess();
//     setLoading(false);
//     if (access) {
//       window.open('/CubeAdmin', '_blank');
//     } else {
//       window.open('/CubeAccessDenied', '_blank');
//     }
  
//   };

  const handleFikeyClick = () => {   
    window.open('/cubeadmin', '_blank');
   };

  return (
    <>
      <Modal {...props} size="sm" aria-labelledby="contained-modal-title-vcenter" centered backdrop="static">
        <Modal.Header closeVariant="white" closeButton={!loading} >
          <Modal.Title id="contained-modal-title-vcenter">Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
          <div className="horizontal-container">
            <Form.Check
              inline
              label="Commentary"
              name=""
              type="checkbox"
              aria-label="Include Filters"
              onChange={loading ? undefined : handlecommentchn}
              checked={Commentchk}
              id={'Include Filters'}
            />
            {/* <div>
            <button
           variant="primary"
          className='cube-button'
          type="button"
          onClick={handleFikeyClick}
          disabled={loading}
          style={{ marginLeft: '10px' }} 
        >
          <GrCube />
        </button>
          </div> */}
            {
              UserAdministrationIcon && (
                <>
                <ul style={{ listStyle: 'none' , margin: 'auto' , marginLeft: '-18px' }}>
                  <div className="vertical-divider" ></div>
                  
                    <li className='setting-button' >
                  <button 
                  type="button"
                    onClick={openUrl}
                    style={{
                      border: 'none',
                      background: 'none',
                    }}
                  >
                    <FiSettings className='setting' />
                  </button>
                  </li>
                  </ul>
                </>
              )
            }
              </div>
            </div>
          <div className="theme-wrap">
            <span>Light</span>
            <ThemeToggle onClick={handleClick} disabled={loading} />
            <span>Dark</span>
          </div>
          <div className='settingspinner'>
            {loading && <SpinnerComponent text="Loading..." />}
          </div>
        </Modal.Body>
      </Modal>
      {showsettingserroralert && (
        <Modal show={true} size="sm">
          <ModalBody>
            <p>Error while applying selection, please try again later!</p>
            <div>
              <button
                className="btn-sm btn btn-dark"
                onClick={() => setShowSettingErrorAlert(false)}
              >
                Close
              </button>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};
export default Settings