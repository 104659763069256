import React, { useEffect, useState, useRef } from 'react';
import CubeAdminGrid from '../Components/Grid/CubeAdminGrid';
import axios from 'axios';
import Config from '../Config';
import { getToken, getUsername } from '../Components/Services/MsalService';
import SpinnerComponent from '../Components/Common/SpinnerComponent';
import { useNavigate } from 'react-router-dom';
import ThemeToggle from '../Components/ColorTheme/ThemeToggle';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducer } from '../Redux/Reducers/types';
import { setCommentaryStatusAction } from '../Redux/Actions/fieldSortAction';

interface GridItem {
  id: string;
  name: string;
}

function CubeAdmin() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  
  const fieldSort = useSelector((state: RootReducer) => state.fieldSort);
  const [sapCompanies, setSapCompanies] = useState<GridItem[]>([]);
  const [theme, setTheme] = useState(localStorage.getItem('selectedTheme') || 'light');
  const [themeLoaded, setThemeLoaded] = useState(false); // New state to track theme loading
  const [Commentchk, setCommentchk] = useState(fieldSort.Commentarystatus);
  const [selectedOptions, setSelectedOptions] = useState<GridItem[]>([{ id: '-20', name: 'All - SAP Legal Entity' }]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasAccess, setHasAccess] = useState(false);
  const [loader , setloader] = useState(false);
  const navigate = useNavigate();

  // Function to set Dark Mode
  const setDarkMode = () => {
    document.querySelector('body')?.setAttribute('data-theme', 'dark');
    localStorage.setItem('selectedTheme', 'dark');
    setTheme('dark');
  };

  // Function to set Light Mode
  const setLightMode = () => {
    document.querySelector('body')?.setAttribute('data-theme', 'light');
    localStorage.setItem('selectedTheme', 'light');
    setTheme('light');
  };

  // Fetch user settings (including theme preference)
  const getUserSettings = async () => {
    try {
      const userAlias = await getUsername();
      const response = await axios.get(`${Config.apiBaseAddress}${Config.apiGetUserSettings}?userAlias=${userAlias}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await getToken()}`,
        },
      });

      const responseData = response.data.isCommentaryOpt;
      if (responseData === true) {
        dispatch(await setCommentaryStatusAction(responseData));
      }

      const userTheme = response.data.theme;
      if (userTheme === 'dark') {
        setDarkMode();
      } else {
        setLightMode();
      }

      setThemeLoaded(true); // Set themeLoaded to true after fetching and setting the theme
    } catch (error) {
      console.error('Failed to fetch user settings:', error);
      setThemeLoaded(true); // Even if there's an error, set themeLoaded to true to stop loading
    }
  };

  // Check access rights
  const checkAccess = async () => {
    setIsLoading(true);
    const access = await getCommentAccess();
    setHasAccess(access);
    setIsLoading(false);
    if (!access) {
      navigate('/CubeAccessDenied');
    }
  };

  // Fetch SAP companies
  const fetchSapCompanies = async () => {
    try {
      const response = await axios.get(
        `${Config.apiBaseAddress}${Config.apiAllSAPCompanyData}?ReportID=1&UserAlias=${await getUsername()}`,
        { headers: { Authorization: `Bearer ${await getToken()}` } }
      );

      if (response.status === 200) {
        setIsLoading(false);
      }

      const companyData = response.data.sapCompanies.map((company: any) => ({
        id: company.companyCode,
        name: company.companyDescription,
      }));

      setSapCompanies(companyData);
    } catch (error) {
      console.error('Error fetching SAP companies:', error);
      setIsLoading(false);
    }
  };

  const getCommentAccess = async () => {
    try {
      const token = await getToken();
      const response = await axios.get(`${Config.apiBaseAddress}${Config.apiCommentAccess}`, {
        params: { param: 'LEGALENTITY' },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      return response.status === 200 && response.data;
    } catch (error) {
      console.error('Failed to fetch comment access details:', error);
      return false;
    }
  };

  // Handle theme toggle
  const handleClick = async (e: any) => {
    setLoading(true);
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);

    const savePreference = {
      Commentarystatus: Commentchk,
      Theme: newTheme,
    };
    await savesetting(savePreference);
  };

  // Save user settings
  const savesetting = async (savePreference: any) => {
    try {
      savePreference.IsCommentaryOpt = savePreference.Commentarystatus;
      savePreference.userAlias = await getUsername();
      const response = await axios.post(
        `${Config.apiBaseAddress}${Config.apiSaveUserSettings}`,
        JSON.stringify(savePreference),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${await getToken()}`,
          },
        }
      );
      if (response.status === 200) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error('Failed to save settings:', error);
    }
  };

  useEffect(() => {
    fetchSapCompanies();
    getUserSettings(); // Fetch user settings on page load
    checkAccess();
  }, []);

  if (!themeLoaded) {
    // Show loading spinner until theme is loaded
    return (
      <div className="settingspinner">
        <SpinnerComponent text="Loading..." />
      </div>
    );
  }

  return (
    hasAccess ? (
      <div className="Grid-wrapp">
        <div className="cube-scroll-container">
          <div className='cube-container'>
            <span className='cube-title'>
              Commentary Permissions
            </span>
            <span className="cube-theme-wrap">
              <span>Light</span>
              <ThemeToggle onClick={handleClick} disabled={loading} />
              <span>Dark</span>
            </span>
          </div>
          <div>
            {!isLoading && (
              <>
                {loading && (
                  <div className='overlayloader'>
                    <SpinnerComponent text="Loading..." />
                  </div>
                )}
                <div>
                  <CubeAdminGrid />
                </div>
              </>
            )}
          </div>
        </div>
        {loading && (
          <div className="overlay">
          </div>
        )}
      </div>
    ) : null
  );
  
}

export default CubeAdmin;
