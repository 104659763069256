import React, { useState, useEffect, useRef, RefObject } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { FaFilter, FaTimes } from "react-icons/fa";
import { CiFilter } from "react-icons/ci";
import { FaRegWindowClose } from "react-icons/fa";
import { AiOutlineDelete } from "react-icons/ai";
import { IoIosRefresh } from "react-icons/io";

import axios from 'axios';
import Config from '../../Config';
import { getToken, getUsername } from '../Services/MsalService';
import Spinner from 'react-bootstrap/esm/Spinner';
import SpinnerComponent from '../Common/SpinnerComponent';
import { Modal } from 'react-bootstrap';
import { LuAlertCircle } from "react-icons/lu";
import { FALSE } from 'sass';


interface GridItem {
  id: string;
  name: string;
}

interface ApiResponse {
  sapCompanies: string;
  errorCodeValue: string;
  requestedUserAlias: string;
}

interface GridData {
  accessID: number;
  userName: string;
  userAlias: string;
  roleId:number;
}



interface User {
  userAlias: string;
  userName: string;
  title: string;
}

interface GridComponentState {
  data: GridData[];
  pageData: GridData[];
  currentPage: number;
  searchTerm: string;
  showModal: boolean;
  totalPages: number;
  viewType: string;
  filterName: string;
  filterAlias: string;
  filterOptionName: string;
  filterOptionAlias: string;
  showFilterDropdownName: boolean;
  showFilterDropdownAlias: boolean;
  
}

const ITEMS_PER_PAGE = 7;

const filterOptions = [
  "contains",
  "is equal to",
  "is not equal to",
  "starts with",
  "does not contain",
  "ends with"
];

const CubeAdminGrid = () => {
  const dropdownNameRef = useRef<HTMLDivElement>(null);
  const dropdownAliasRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement | null>(null); 
 // const [selectedName, setSelectedName] = useState<string | null>(null);
  const [scrollbarWidth, setScrollbarWidth] = useState(0);
  const [hasScrollbar, setHasScrollbar] = useState(false);
  const [roleId, setRoleId] = useState(1);
  const gridContentRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [messageContent, setMessageContent] = useState('');
  const [fade, setFade] = useState(false);
  const [showheader , setshowheader] = useState(true);
 // const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownNameRef.current &&
        !dropdownNameRef.current.contains(event.target as Node) &&
        dropdownAliasRef.current &&
        !dropdownAliasRef.current.contains(event.target as Node)
      ) {
        setState((prevState) => ({
          ...prevState,
          showFilterDropdownName: false,
          showFilterDropdownAlias: false,
        }));
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

 
  const [state, setState] = useState<GridComponentState>({
    data: [],
    pageData: [],
    currentPage: 1,
    searchTerm: '',
    showModal: false,
    totalPages: 1,
    viewType: 'admins',
    filterName: '',
    filterAlias: '',
    filterOptionName: filterOptions[0],
    filterOptionAlias: filterOptions[0],
    showFilterDropdownName: false,
    showFilterDropdownAlias: false,
  });

  const [searchResults, setSearchResults] = useState<User[]>([]); 
  const [tempsearchResults, settempSearchResults] = useState<User[]>([]); 

  const [selectedName, setSelectedName] = useState<string>('');
  const [selectedAlias, setSelectedAlias] = useState<string>('');
  const [isLoadingSearch , setIsLoadingSearch] = useState(false);
  const [isGridLoader , setIsGridLoader] = useState(false);
  
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); 
  const [sapCompanies, setSapCompanies] = useState<GridItem[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [selectedOptions, setSelectedOptions] = useState<GridItem[]>([
    { id: '-20', name: 'All - SAP Legal Entity' }
  ]);

  const fetchSapCompanies = async () => {
    try {
      //setIsLoading(true);
      const response = await axios.get(
        `${Config.apiBaseAddress}${Config.apiAllSAPCompanyData}?ReportID=1&UserAlias=${await getUsername()}`,
        { headers: { "Authorization": `Bearer ${await getToken()}` } }
      );

      if(response.status === 200){
        setIsLoading(false);

      }
  
      const companyData = response.data.sapCompanies.map((company: any) => ({
        id: company.companyCode,
        name: company.companyDescription,
      }));
  
      setSapCompanies(companyData);
    } catch (error) {
      console.error('Error fetching SAP companies:', error);
      setIsLoading(false);
    } 
  };

  useEffect(() => {
    fetchSapCompanies();
  }, []);


  useEffect(() => {
    // Focus the input when the component mounts
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select(); // Select the text in the input
    }
  }, []);

  const [IsSAP, setIsSap] = useState(false);
  const specialOptionIds = ['-20', '-10'];

  const handleOptionSelect = (option: GridItem) => {
    const isSpecialOption = specialOptionIds.includes(option.id);
    const hasOtherSelection = selectedOptions.some(o => !specialOptionIds.includes(o.id));
    const hasSpecialSelection = selectedOptions.some(o => specialOptionIds.includes(o.id));

    // Add this new logic for checking if both -10 and -20 are selected
    const hasBothSelections = 
    (Number(option.id) === -10 && selectedOptions.some(o => Number(o.id) === -20)) ||
    (Number(option.id) === -20 && selectedOptions.some(o => Number(o.id) === -10));

    if ((isSpecialOption && hasOtherSelection) || (hasSpecialSelection && !isSpecialOption) || (hasBothSelections) ) {
      setIsSap(true);
      setMessageContent("You cannot select All SAP Legal Entity and All C+E with other SAP Company combinations.");
      setShowMessageModal(true);      
      setshowheader(true);
      setSelectedOptions([]);
      return;
    }
    if (selectedOptions.length >= 5) {
      setMessageContent("You can only select a maximum of 5 SAP Company Codes.");
      setshowheader(true);
      setShowMessageModal(true);
      return;
    }
    setIsSap(false);
    if (selectedOptions.some((o) => o.id === option.id)) {
      handleRemoveSelection(option);
    } else if (selectedOptions.length < 5) {
      setSelectedOptions([...selectedOptions, option]);
      // Keep the dropdown open after selection
      setSearchValue(''); // Optional: Clear search value
    }
  };



  const handleRemoveSelection = (option: GridItem) => {
    setSelectedOptions(selectedOptions.filter((o) => o.id !== option.id));
  };

  const filteredOptions = sapCompanies.filter((company) =>
    company.name.toLowerCase().includes(searchValue.toLowerCase())
  );

  useEffect(() => {
    const handleDocumentClick = (event: any) => {
      if (!event.target.closest('.dropdown')) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);


  useEffect(() => {
    getCommentAccessUsers();
  }, [state.viewType, selectedOptions]);
  useEffect(() => {
    applyFilters();
  }, [state.filterName, state.filterAlias, state.filterOptionName, state.filterOptionAlias, state.data, state.currentPage,state.viewType]);

  const reportType = "LEGALENTITY";
  const companyCode = selectedOptions.map(option => option.id);

 const getCommentAccessUsers = async () => {
    try {
      setIsGridLoader(true);
      const token = await getToken();
      const response = await axios.get(
        `${Config.apiBaseAddress}${Config.apiCommentAccessUsers}`,
        {
          params: { 
            roleId: roleId,
            companyCode: companyCode.join(','),
            reportType: reportType,
          },  
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200 && response.data) {
        const usersData = response.data;
        const AdminData = usersData.filter((user: any) => user.roleId === 1);
        const UserData = usersData.filter((user: any) => user.roleId === 2);

        // Set the data directly from API
        setState((prevState) => ({
          ...prevState,
          data: state.viewType === 'admins' ? AdminData : UserData,
        }));

        setIsLoading(false);
        setIsGridLoader(false);
        applyFilters();
      }
    } catch (error) {
      console.error("Failed to fetch comment access details:", error);
      setIsLoading(false);
      setIsGridLoader(false);
    }
  };
 

  

  const applyFilters = () => {
    let filteredData = [...state.data]; // Use in-memory data

    if (state.filterName) {
      filteredData = filterData(filteredData, 'userName', state.filterName, state.filterOptionName);
    }

    if (state.filterAlias) {
      filteredData = filterData(filteredData, 'userAlias', state.filterAlias, state.filterOptionAlias);
    }

    // Paginate filtered data
    const totalPages = Math.ceil(filteredData.length / ITEMS_PER_PAGE);
    const pageData = filteredData.slice((state.currentPage - 1) * ITEMS_PER_PAGE, state.currentPage * ITEMS_PER_PAGE);

    setState((prevState) => ({
      ...prevState,
      pageData,
      totalPages: totalPages || 1,
    }));
  };
  const filterData = (data: GridData[], key: 'userName' | 'userAlias', value: string, option: string) => {
    switch (option) {
      case "is equal to":
        return data.filter(item => item[key].toLowerCase() === value.toLowerCase());
      case "is not equal to":
        return data.filter(item => item[key].toLowerCase() !== value.toLowerCase());       
      case "starts with":
        return data.filter(item => item[key].toLowerCase().startsWith(value.toLowerCase()));
      case "contains":
        return data.filter(item => item[key].toLowerCase().includes(value.toLowerCase()));
      case "does not contain":
        return data.filter(item => !item[key].toLowerCase().includes(value.toLowerCase()));
      case "ends with":
        return data.filter(item => item[key].toLowerCase().endsWith(value.toLowerCase()));
      default:
        return data;
    }
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [itemIdToDelete, setItemIdToDelete] = useState("");

  const openPopup = (id: string) => {
    setIsPopupOpen(true);
    setItemIdToDelete(id);
  };

  const confirmDelete = async () => {
    if (itemIdToDelete !== null) {
      try {
      const deleteResult = await deleteCommentAccessUsers();
      console.log("delete result: ", deleteResult);
  
      if (deleteResult === 1) {
        setMessageContent(`${state.viewType === 'admins' ? 'Admin' : 'User'} has been deleted successfully.`);
        setshowheader(false);
      } else if (deleteResult === -1) {
        setMessageContent("Unable to delete. At least one admin should be present for a SAP Company(s).");
        setshowheader(true);
      } else if (deleteResult === -2) {
        setMessageContent("Unable to delete. You do not have permision to delete User/Admin");
        setshowheader(true);
      }else if(deleteResult === 0) {
        setMessageContent("Error has occurred. Please contact Administrator.");
        setshowheader(true);
      }
      setShowMessageModal(true); // Show the modal pop-up with the message
     } catch (error) {
      console.error("Error deleting user:", error);
      setMessageContent("Error has occurred. Please contact Administrator.");
      setShowMessageModal(true);  
      setshowheader(true);
    }
    await getCommentAccessUsers();
    }
    setIsPopupOpen(false);
  }
const handleAddItem = async () => {
  try {
    const errorValue = await AddCommentAccessUsers();
    console.log("AddErrorValue:", errorValue);
    handleCloseModal();

    if (errorValue === "1") {
      setMessageContent(`${state.viewType === 'admins' ? 'Admin' : 'User'} has been added successfully.`);
      setshowheader(false);
    } else if (errorValue === "-1") {
      setMessageContent(`${state.viewType === 'admins' ? 'Admin' : 'User'} is already present.`);
      setshowheader(true);
    } else if (errorValue === "2") {
      setMessageContent("You are not authorized to add admins/users for the selected SAP Company(s).");
      setshowheader(true);
    } else if (errorValue === "-3") {
      setMessageContent("Super admin cannot be added as a user.");
      setshowheader(true);
    }
   else if (errorValue === "-2" || "3") {
    setMessageContent(`${state.viewType === 'admins' ? 'User' : 'Admin'} is already present.`);
    setshowheader(true);
  }
  // else if (errorValue === "3") {
  //   setMessageContent(`${state.viewType === 'admins' ? 'User' : 'Admin'} already has access`);
  //   setshowheader(true);
  // }
    setShowMessageModal(true);  
    setSelectedName('');


  } catch (error) {
    console.error("Error adding user:", error);
    setMessageContent("Error has occurred. Please contact Administrator.");
    setshowheader(true);
  }
  await getCommentAccessUsers();
};

  const deleteCommentAccessUsers = async () =>{
    try {
      const token = await getToken();
      const response = await axios.get(
        `${Config.apiBaseAddress}${Config.apiDeleteCommentAccessUsers}`,
        {
          params: {     
            userAlias: itemIdToDelete ,
            companyCode: companyCode.join(','),
            roleId: roleId,
            selectedCompanyCode: companyCode.join(','),
            reportType: "LEGALENTITY"},  
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Delete string: ", response.data);
      let deleteValue = 0
      if (response.status === 200 && response.data) {
        deleteValue = response.data; 
        
      }
      console.log("delete value :", deleteValue)
      return deleteValue;
    } catch (error) {
      console.error("Failed to delete access user:", error);
      return 0;
    }
  };

  const AddCommentAccessUsers = async () => {
    try {
        const startTime = Date.now();
        const token = await getToken();
        console.log("Token retrieved in", Date.now() - startTime, "ms:", token);
        
        const response = await axios.post(
            `${Config.apiBaseAddress}${Config.apiAddCommentUser}`,
            {},
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                params: {     
                    txtUserAlias: selectedAlias,
                    txtUserName: selectedName,
                    txtCompanyCode: companyCode.join(','),
                    roleId: roleId, 
                    reportType: "LEGALENTITY"
                },
            }
        );
        console.log("API response time:", Date.now() - startTime, "ms");
        console.log("Add response string: ", response.data);

        let addValue = "0";
        if (response.status === 200 && response.data) {
            addValue = response.data.errorCodeValue; 
        }
        console.log("Add value:", addValue);
        return addValue;
    } catch (error) {
        console.error("Failed to add access user:", error);
        return 0;
    }
};

 

  const closePopup = () => {
    setIsPopupOpen(false);
  //  setItemIdToDelete("");
  };


const handlePageChange = (page: number) => {
  setState((prevState) => ({
    ...prevState,
    currentPage: page,
  }));
};

  const handleSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = e.target.value;
    setState((prevState) => ({
      ...prevState,
      searchTerm: newSearchTerm,
    }));

    if(newSearchTerm == ''){
      setSearchResults([]);
      settempSearchResults([]);
      setIsLoadingSearch(false); 
      return ;

    }
    const _recordinstate = tempsearchResults.filter( e => e.userName.toLowerCase().startsWith(newSearchTerm));
    if(_recordinstate.length > 0){

      setSearchResults(_recordinstate);
    }

    else{
      setIsLoadingSearch(true);

    // Call the search API
    if (newSearchTerm) {
      const results = await searchUsersinAD(newSearchTerm);
      setSearchResults(results);
      const combined = [...tempsearchResults, ...results];
      // Set the combined list to state

      settempSearchResults(combined);
      setIsLoadingSearch(false);
     } 
    else {
    
      setSearchResults([]);
      settempSearchResults([]);
      setIsLoadingSearch(false); 
    }
  }
  };


  const searchUsersinAD = async (searchString: string): Promise<User[]> => {
    try {
      const token = await getToken(); 
      const response = await axios.get(
        `${Config.apiBaseAddress}${Config.apiSearchUsersInAD}`,
        {
          params: { searchString },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log('search response: ', response.data);

      if (response.status === 200 && response.data) {
        return Array.isArray(response.data)
          ? response.data.map((item: any) => ({
              userAlias: item.alias,
              userName: item.displayName,
              title: item.title, 
            }))
          : [];
      }

      return [];
    } catch (error) {
      console.error('Search failed:', error);
      return [];
    }
  };
  const handleok = () => {
     setShowMessageModal(false);
  }

  const handleSearchResultClick = (name: string , Alias: string) => {
    setSelectedName(name); 
    setSelectedAlias(Alias);
    setState((prevState) => ({
      ...prevState,
      searchTerm: name, // Update the search term to the selected name
    }));
    setSearchResults([]); // Optionally clear the search results after selection
    settempSearchResults([]);
  };
  const handleOpenModal = () => {
    setState((prevState) => ({
      ...prevState,
      showModal: true,
    }));
  };

  const handleCloseModal = () => {
    setState((prevState) => ({
      ...prevState,
      showModal: false,
      searchTerm: '',
    }));
  };

  const setViewType =(viewType: string) => {
    const storageKey = viewType === "admins" ? "gridAdminData" : "gridUserData";
    setState((prevState) => ({
      ...prevState,
      viewType,
      data: JSON.parse(localStorage.getItem(storageKey) || "[]"),
      currentPage: 1,
    }));
    setRoleId(viewType === "admins" ? 1 : 2);
    //applyFilters();
  };

  const toggleDropdown = (type: 'name' | 'alias') => {
    if (type === 'name') {
      setState((prevState) => ({
        ...prevState,
        showFilterDropdownName: !prevState.showFilterDropdownName,
        showFilterDropdownAlias: false,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        showFilterDropdownAlias: !prevState.showFilterDropdownAlias,
        showFilterDropdownName: false,
      }));
    }
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>, type: 'name' | 'alias') => {
    if (type === 'name') {
      setState((prevState) => ({
        ...prevState,
        filterName: e.target.value,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        filterAlias: e.target.value,
      }));
    }
  };

  const handleClearFilter = (type: 'name' | 'alias') => {
    if (type === 'name') {
      setState((prevState) => ({
        ...prevState,
        filterName: '',
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        filterAlias: '',
      }));
    }
  };

  const handleFilterOptionChange = (option: string, type: 'name' | 'alias') => {
    if (type === 'name') {
      setState((prevState) => ({
        ...prevState,
        filterOptionName: option,
        showFilterDropdownName: false,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        filterOptionAlias: option,
        showFilterDropdownAlias: false,
      }));
    }
  };

  if (isLoading) {
    return (
    <div className='settingspinner'>
       <SpinnerComponent text="Loading..." />
    </div>
    );
  }


  
    console.log("pagedata : ", state.pageData);
 
    const sortedData = state.pageData ? [...state.pageData].sort((a, b) => a.userName.localeCompare(b.userName)) : [];
    console.log("chack@@@@",sortedData.length)


 

    return (
    <div>    
<div className='Grid-wrap'>
<div className='grid-header'>


    <div style={{ display: 'flex', alignItems: 'center' , marginLeft: '20px' }}>
      <label style={{ display: 'flex', alignItems: 'center', marginRight: '40%' }}>
        <input
          className='radio-check'
          type='radio'
          value='admins'
          checked={state.viewType === 'admins'}
          onChange={() => setViewType('admins')}
        />
        <span style={{ marginLeft: '8px', fontSize: '0.8em' }}>Admins</span>
      </label>
      <label style={{ display: 'flex', alignItems: 'center' }}>
        <input
          className='radio-check'
          type='radio'
          value='users'
          checked={state.viewType === 'users'}
          onChange={() => setViewType('users')}
        />
        <span style={{ marginLeft: '8px', fontSize: '0.8em' }}>Users</span>
      </label>
    </div>

    <div className="dropdown" style={{ display: 'flex', alignItems: 'center', marginRight: '20px', position: 'relative', marginLeft: '20px', marginBottom: '10`px' }}>
                <span style={{ fontSize: '0.85em' }}>SAP Company: </span>
                <div className='sap-dropdown'
            style={{
                marginLeft: '10px',
                display: 'flex',
                alignItems: 'center',
                border: isDropdownOpen ? '2px solid var(--accent-color)' : '1.5px solid #ccc',
                padding: '3px 5px',
                borderRadius: '4px',
                cursor: 'pointer',
                width: '250px',
                whiteSpace: 'nowrap',
                overflowX: 'auto',
                overflowY: 'hidden',
                fontWeight: 'normal'
            }}
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
            {selectedOptions.length === 1 ? (
                <span style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    backgroundColor: '#e0e0e0',
                    border: '1px solid #ccc',
                    marginRight: '5px',
                    padding: '5px',
                    borderRadius: '4px',
                    whiteSpace: 'nowrap',
                    color: 'black',
                    fontSize: '0.8em',
                }}>
                    {selectedOptions[0].name}
                </span>
            ) : selectedOptions.length > 1 ? (
                <span style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    backgroundColor: '#e0e0e0',
                    border: '1px solid #ccc',
                    marginRight: '5px',
                    padding: '5px',
                    borderRadius: '4px',
                    whiteSpace: 'nowrap',
                    color: 'black',
                    fontSize: '0.8em',
                }}>
                    {selectedOptions.length} option(s) selected
                </span>
            ) : (
                <span style={{ color: 'gray', fontSize: '0.8em' , fontWeight: 'normal' }}>Select an option</span>
            )}
            
            {/* Conditionally Render Cross Button */}
            {selectedOptions.length > 0 && (
                <span 
                    style={{
                        marginLeft: 'auto',
                        cursor: 'pointer',
                        fontSize: '1.2em',
                        color: 'gray',
                    }}
                    onClick={(e) => {
                        e.stopPropagation(); 
                        setSelectedOptions([]); // Clears the selected options
                    }}
                >
                    &times;
                </span>
            )}
        </div>
                {isDropdownOpen && (
                  <div className='sap-dropdown'
                    style={{
                      position: 'absolute',
                      top: '100%',
                      left: '6px',
                      transform: 'translateX(-4%)',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      padding: '5px',
                      marginLeft: '103px',
                      marginTop: '1px',
                      width: '250px',
                      fontSize: '0.8em',
                      zIndex: 1000,
                      height: '165px',
                      overflowY: 'auto',
                      fontWeight: 'normal'
                    }}>
                    <input
                      type="text"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      placeholder="Search SAP Companies"
                      autoFocus
                      style={{
                        width: '100%',
                        padding: '5px',
                        marginBottom: '10px',
                        borderRadius: '4px',
                        border: '1px solid #ccc',
                        fontSize: '0.8em',
                      }} 
                    />
                    {filteredOptions.map((option) => (
                      <div
                        className={`option ${selectedOptions.some((o) => o.name === option.name) ? 'selected' : ''}`}
                        key={option.id}
                        style={{
                          padding: '5px',
                          cursor: 'pointer',
                          fontSize: '0.8em',
                        }}
                        onClick={() => handleOptionSelect(option)}
                      >
                        {option.name}
                      </div>
                    ))}
                  </div>
                )}
              </div>
    
    <button
      className='cube-add-button'
      onClick={() => {
        if (selectedOptions.length <= 0) {
          setMessageContent('Please select SAP Company');
          setShowMessageModal(true);
          setshowheader(true);
        } else {
          handleOpenModal();
        }
      }}
      style={{ fontSize: '0.85em' }}
    >
      {state.viewType === 'admins' ? 'Add Admin' : 'Add User'}
    </button>
  </div>

  <div className='grid-design2'>
    <div
      className='grid-header-row'
      style={{ marginRight: hasScrollbar ? scrollbarWidth : 0 }}
    >
      <div style={{ flex: 2, fontWeight: 'bold', borderRight: '2px solid var(--white-color)', minWidth: '150px', fontSize: '0.8em' }}>Name</div>
      <div style={{ flex: 2.01, fontWeight: 'bold', marginLeft: '8px', borderRight: '2px solid var(--white-color)', minWidth: '150px', fontSize: '0.8em' }}>Alias</div>
      <div style={{ flex: 0.475 }}></div>
    </div>

    <div className='grid-filter-row' style={{ marginRight: hasScrollbar ? scrollbarWidth : 0 }}>
      <div
        style={{
          flex: 2,
          position: 'relative',
          borderRight: '2px solid var(--white-color)',
          minWidth: '150px',
          display: 'flex',
          alignItems: 'center',
          fontSize: '0.8em'
        }}
        ref={dropdownNameRef}
      >
        <input
          type="text"
          className="input-filter"
          value={state.filterName}
          onChange={(e) => handleFilterChange(e, 'name')}
          placeholder="Filter by Name"
          style={{ flexGrow: 1 }}
        />
        <CiFilter
          size={20}
          style={{ cursor: 'pointer', marginLeft: '10px', position: 'relative',marginRight: '5px' }}
          onClick={() => toggleDropdown('name')}
        />
        {state.filterName && (
          <FaRegWindowClose
            style={{ cursor: 'pointer', marginLeft: '5px',marginRight: '5px'}}
            onClick={() => handleClearFilter('name')}
          />
        )}
        {state.showFilterDropdownName && (
          <div
            className='filter-icon-dropdown'
          >
            {filterOptions.map(option => (
              <div
                key={option}
                onClick={() => handleFilterOptionChange(option, 'name')}
                className={`dropdown-itemcube ${state.filterOptionName === option ? 'selected' : ''}`}
                style={{ padding: '5px', cursor: 'pointer' }}
              >
                {option}
              </div>
            ))}
          </div>
        )}
      </div>

      <div
        style={{
          flex: 2.01,
          position: 'relative',
          marginLeft: '8px',
          borderRight: '2px solid var(--white-color)',
          minWidth: '150px',
          display: 'flex',
          alignItems: 'center',
          fontSize: '0.8em'
        }}
        ref={dropdownAliasRef}
      >
        <input
          type="text"
          className="input-filter"
          value={state.filterAlias}
          onChange={(e) => handleFilterChange(e, 'alias')}
          placeholder="Filter by Alias"
          style={{ flexGrow: 1 }}
        />
        <CiFilter
          size={20}
          style={{ cursor: 'pointer', marginLeft: '10px', position: 'relative',marginRight: '5px' }}
          onClick={() => toggleDropdown('alias')}
        />
        {state.filterAlias && (
          <FaRegWindowClose
            style={{ cursor: 'pointer', marginLeft: '5px',marginRight: '5px' }}
            onClick={() => handleClearFilter('alias')}
          />
        )}
        {state.showFilterDropdownAlias && (
          <div
            className='filter-icon-dropdown'
          >
            {filterOptions.map(option => (
              <div
                key={option}
                onClick={() => handleFilterOptionChange(option, 'alias')}
                className={`dropdown-itemcube ${state.filterOptionAlias === option ? 'selected' : ''}`}
                style={{ padding: '5px', cursor: 'pointer' }}
              >
                {option}
              </div>
            ))}
          </div>
        )}
      </div>

      <div style={{ flex: 0.475 }}></div>
    </div>

    {isGridLoader ? (
      <div className='AMgridspinner'>
        <SpinnerComponent text={'loading'} />
      </div>
    ) : (
      <>
        <div className='grid-content'>
          {(sortedData && sortedData.length > 0) ? (
            <Virtuoso
              style={{ height: '100%', overflow: 'hidden', fontSize: '0.8em' }}
              data={sortedData}
              itemContent={(index, item) => (
                <div
                  className={`grid-stripe ${(index % 2 === 0) ? 'even' : ''}`}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '6px',
                    minWidth: '300px'
                  }}
                >
                  <div style={{ flex: 2, borderRight: '2px solid var(--white-color)', padding: '0 10px', minWidth: '150px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                    {item.userName}
                  </div>
                  <div style={{ flex: 2, borderRight: '2px solid var(--white-color)', marginLeft: '8px', padding: '0 10px', minWidth: '150px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                    {item.userAlias}
                  </div>
                  <div style={{ flex: 0.5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <AiOutlineDelete
                      onClick={() => openPopup(item.userAlias)}
                      style={{ cursor: 'pointer'}}
                    />
                    {isPopupOpen && (
                      <Modal
                        show={isPopupOpen}
                        onHide={closePopup}
                        size="sm"
                        centered
                        backdrop="static"
                        backdropClassName="invisible-backdrop"
                      >
                        <Modal.Header closeVariant="white" closeButton>
                          <Modal.Title style={{ fontSize: '1.0em' }}>
                            <LuAlertCircle style={{ marginRight: '5px'}} />
                            Warning
                          </Modal.Title>
                        </Modal.Header >
                        <Modal.Body style={{ border: '1px solid gray' }}>
                          <div style={{ paddingBottom: '15px', paddingTop: '10px', fontSize: '0.85em' }}>
                            Are you sure you want to delete this {state.viewType === 'admins' ? 'Admin' : 'User'}?
                          </div>
                          <Modal.Footer>
                            <button className='popup-button' onClick={confirmDelete} style={{ fontSize: '0.8em' }}>
                              Ok
                            </button>
                            <button className='popup-button' onClick={closePopup} style={{ fontSize: '0.8em' }}>
                              Cancel
                            </button>
                          </Modal.Footer>
                        </Modal.Body>
                      </Modal>
                    )}
                  </div>
                </div>
              )}
            />
          ) : (
            <div style={{ textAlign: 'center', padding: '20px', color: 'var(--cube-text-color)', fontSize: '0.8em' }}>
              No records to display
            </div>
          )}
        </div>

        <Modal
          show={showMessageModal}
          onHide={() => setShowMessageModal(false)}
          size="sm"
          backdrop="static"
          centered
        >
          {showheader && (
          <Modal.Header closeVariant="white" closeButton>
            <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '1.0em' }}>
              <LuAlertCircle style={{ marginRight: '5px' }} />
              Warning
            </Modal.Title>
          </Modal.Header>
          )}
          <Modal.Body>
            <p style={{ fontSize: '0.8em' }}>{messageContent}</p>
            <div className='button-design-alert'>
              <button className='alert-button' onClick={handleok} style={{ fontSize: '0.8em' }}>OK</button>
            </div>
          </Modal.Body>
        </Modal>

        <div className='grid-pagination' style={{ borderTop: (sortedData.length === 7) ? '0px' : '1px solid #ccc', fontSize: '0.8em' }}>
          <button
            onClick={() => handlePageChange(state.currentPage - 1)}
            disabled={state.currentPage === 1}
            style={{ margin: '0 5px' }}
          >
            &lt;
          </button>
          {Array.from({ length: state.totalPages }, (_, i) => (
            <button
              key={i}
              onClick={() => handlePageChange(i + 1)}
              style={{ margin: '0 5px', fontWeight: state.currentPage === i + 1 ? 'bold' : 'normal', backgroundColor: '#225b62', color: 'white' }}
            >
              {i + 1}
            </button>
          ))}
          <button
            onClick={() => handlePageChange(state.currentPage + 1)}
            disabled={state.currentPage === state.totalPages}
            style={{ margin: '0 5px' }}
          >
            &gt;
          </button>
        </div>
      </>
    )}
  </div>

  <div style={{ paddingTop: '24px', textAlign: 'center' }}>
    <p style={{
      fontSize: '0.8em',
      margin: '20px auto',
      display: 'inline-block',
      textAlign: 'left',
      color: 'var(--cube-text-color)'
    }}>
      <span style={{ display: 'inline-block', marginRight: '100px' }}>
        <strong>User</strong><br />
        Permission to add/edit comments
      </span>

      <span style={{ display: 'inline-block', marginLeft: '140px' }}>
        <strong>Admin</strong><br />
        Permission to add/edit comments + Permission to add/remove users and admins
      </span>
      <br /><br />

      <span style={{ display: 'inline-block', marginRight: '60px' }}>
        <strong>Super Admin</strong><br />
        Permission to add/remove users and admins for all SAP companies
      </span>

      <span style={{ display: 'inline-block' }}>
        <strong>Read only Admin</strong><br />
        Permission to view users and admins for all SAP companies
      </span>
    </p>
  </div>

  {state.showModal && (
    <Modal
  show={state.showModal}
  onHide={handleCloseModal}
  centered
  backdrop="static"
  dialogClassName="custom-modal" // Custom class to control width
>
  <Modal.Header closeVariant="white"  closeButton>
    <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '1.0em' }}>
      {`Add ${state.viewType === 'admins' ? 'Admin' : 'User'}`}
    </Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <div style={{ paddingBottom: '20px', paddingTop: '10px', fontSize: '1.0em' }}>
      <a>SAP Company</a>
      <div>
        {selectedOptions.map((option) => (
          <span
            key={option.name}
            style={{ fontWeight: 'bold', fontSize: '1.0em', paddingLeft: '20px' }}
          >
            . {option.name} <br />
          </span>
        ))}
      </div>
    </div>

    <div style={{ position: 'relative', width: '100%', fontSize: '1.0em' }}>
      <input
        className="add-dropdown"
        type="text"
        value={state.searchTerm}
        onChange={handleSearch}
        placeholder={`Search ${state.viewType === 'admins' ? 'admin' : 'user'} name...`}
        style={{
          paddingRight: '60px',
          width: '100%',
          height: '40px',
          border: '1px solid gray',
          fontSize: '1.0em'
        }}
      />
      {isLoadingSearch && (
        <div
          style={{
            position: 'absolute',
            right: '40px',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        >
          <Spinner size="sm" />
        </div>
      )}
      {state.searchTerm && (
        <button
          style={{
            position: 'absolute',
            right: '10px',
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
            color: 'var(--text-color)'
          }}
          onClick={() => setState({ ...state, searchTerm: '' })}
        >
          &times;
        </button>
      )}
    </div>

    {!isLoadingSearch && state.searchTerm && searchResults.length > 0 && (
      <div className="search-results">
        {searchResults
          .filter((item) => item.userName.toLowerCase().includes(state.searchTerm.toLowerCase()))
          .map((item) => (
            <div
              key={item.userAlias}
              onClick={() => handleSearchResultClick(item.userName, item.userAlias)}
              style={{
                padding: '5px',
                cursor: 'pointer',
                backgroundColor: selectedName === item.userName ? '#d3d3d3' : 'transparent',
                transition: 'background-color 0.3s',
              }}
              onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = 'var(--cube-search-color)')}
              onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = selectedName === item.userName ? '#d3d3d3' : 'transparent')}
            >
              <div className="searchname">{item.userName}</div>
              <div className="searchtitle" style={{ color: 'var(--cube-searchresult-color)' }}>{item.title}</div>
            </div>
          ))}
      </div>
    )}

    <Modal.Footer>
      <button
        className="cube-add-button"
        onClick={() => {
          if (selectedName) {
            handleAddItem();
          } else {
            handleCloseModal();
            setMessageContent('Invalid input');
            setShowMessageModal(true);
            setshowheader(true);
          }
        }}
        style={{ fontSize: '0.8em' }}
      >
        Save
      </button>
    </Modal.Footer>
  </Modal.Body>
</Modal>
  )}
</div>
      </div>
  );
};

export default CubeAdminGrid;