import axios from "axios";
import { getToken } from "../../Components/Services/MsalService";
import { SET_MCIO_FOOTER , GET_MCIO_USERDATA ,ERROR_MCIO,SET_MCIO_INTIAL,SET_MCIO_DATA}  from "../ActionContants/constants";
import Config from "../../Config";

export const setMCIOFooter = (isMcioFooter: boolean) => ({
  type: SET_MCIO_FOOTER,
  payload: isMcioFooter,
});

export const getMcioUserdata = async (payload: any,selRoleID:any) => 
  {
    try {

    const token = await getToken();
    const response = await axios.get(
      `${Config.apiBaseAddress}${Config.apiMCIOGetCommentAccessUsers}`,
      {
        params:payload,  
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
    const apiRes = response.status;
    if (response.status === 200) {
      const usersData = response.data;
      const Data = usersData.filter((user: any) => user.roleID === selRoleID);
      const _payload:any = {
        data:Data,
        gData:response.data
      }
      return {
        type: GET_MCIO_USERDATA,
        payload: _payload,
        apiResp: apiRes,
      };
    } else if (response.status === 500) {
      return {
        type: ERROR_MCIO,
        apiResp: apiRes,
      };
    } else {
      return {
        type: ERROR_MCIO,
        payload: null,
      };
    }
  }
   catch (error) {

    console.error("Failed to fetch classes:", error);
    const apiRes = 500;
    if (apiRes == 500) {
      return {
        type: ERROR_MCIO,
        payload: null,
        apiResp: 500,
      };
    } else {
      return {
        type: ERROR_MCIO,
      };
    }
  }

};

export const setMCIOData= async (payload: any) => ({
  type: SET_MCIO_DATA,
  payload: payload,
});




