import React, { useEffect, useState } from "react";
import DataGrid from "../Components/DataGrid";
import DataChart from "../Components/DataChart";
import DataGridControl from "../Components/GridController/DataGridControl";
import SlidePanel from "../Components/SlidePanel";
import ExportGrid from "../Components/ExportGrid";
import {
  setExpandAllAction,
  setExpandCollapseAction,
  setTDTypeAction,
} from "../Redux/Actions/qtdDataActions";
import { useDispatch } from "react-redux";
import Store from "../Redux/Store/Store";
import Config from "../Config";
import axios from "axios";
import { setGridDataAction } from "../Redux/Actions/qtdDataActions";
import { exportToExcelAction } from "../Redux/Actions/exportToExcelAction";
import { exportToPPTAction } from "../Redux/Actions/exportToPPTAction";
import { exportToExcelValues } from "../Components/Common/getExportToExcelData";
import { setExportBtnDisableAction } from "../Redux/Actions/exportBtnDisableAction";
import { getDownloadExcelData } from "../Components/Common/SupportingFunctions";
import { getDownloadPPTData } from "../Components/Common/SupportingFunctions";
import { setCheckBoxValue } from "../Redux/Actions/includeFilterAction";
import { Modal, ModalBody } from "react-bootstrap";
import GridComponent from "../Components/Grid/GridComponent";
import PowerBI from "./PowerBI";
import { useCombinedContext } from "./PowerBIContext";

interface Props {
  todate: any;
}

export const Mtd: React.FC<Props> = (Props) => {
  // Showing Export Modal Popup
  const storeData = Store.getState();
  // console.log(storeData);
  const dispatch = useDispatch();
  //
  const { todate } = Props;

  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(false);
  const [showExportGridErrorAlert, setShowExportGridErrorAlert] =
    useState(false);
  const [noGridDataAlert, setNoGridDataAlert] = useState(false);
  // const [apiResNotOk, setApiResNotOk] = useState(false);

  const handleModalHide = () => {
    setModalShow(false);
  };

  const handleCheckBox = () => {
    dispatch(setCheckBoxValue(!filter));
  };

  const exportToExcle = (selected: any) => {

    setLoading(true);
    let parameters = exportToExcelValues();
    //parameters.selectedFilterForSegmentDataModel.selectedClass = selected.Classes.map((e: any) => e.classId)
    //.join(",");
    //parameters.selectedClass= parameters.selectedClass != '' ? parameters.selectedClass.filter( (e:any) => selected.Classes.filter( (item:any) => item.classId == e.channelClassId).length > 0):[];
    let exportclassess = selected.Classes.map((e: any) => e.exportName)
    parameters.selectedSheet = [...parameters.selectedSheet, ...exportclassess]
    if (selected.ReportType == "PPT")
      getExportToPPTData(parameters);
    else
      if ((parameters.selectedSheet.length > 1) && ((selected.ReportType == "Excel") || (parameters.selectedSubClass != 0))) {
        getExportToExcelData(parameters);
      }
      else {
        setLoading(false);
        // setApiResNotOk(true);
        setModalShow(false);
        setFilter(false);
        setNoGridDataAlert(true)
      }
  };

  const getExportToExcelData = async (exportparameters: any) => {
    console.log("exportparameters ==>", exportparameters);
    exportparameters.browserTabUniqueId = sessionStorage.getItem('BrowserTabUniqueId');
    const apiResponse: any = await exportToExcelAction(exportparameters);
    console.log("api checking???>>", apiResponse);
    if (apiResponse) {
      if (apiResponse.payload && apiResponse.payload.status === 200) {
        getDownloadExcelData(apiResponse);
        setLoading(false);
        // setApiResNotOk(false);
        setModalShow(false);
        setFilter(false);
      }
      else {
        setLoading(false);
        // setApiResNotOk(true);
        setModalShow(false);
        setFilter(false);
        setShowExportGridErrorAlert(true);
      }
    }
    else {
      setLoading(false);
      // setApiResNotOk(true);
      setModalShow(false);
      setFilter(false);
      setNoGridDataAlert(true)
    }
  };
  const getExportToPPTData = async (exportparameters: any) => {

    const apiResponse: any = await exportToPPTAction(exportparameters);
    // console.log(apiResponse);
    if (apiResponse.payload) {
      if (apiResponse.payload.status === 200) {

        getDownloadPPTData(apiResponse);
        setLoading(false);
        // setApiResNotOk(false);
        setModalShow(false);
        setFilter(false);
      } else {
        setLoading(false);
        // setApiResNotOk(true);
        setModalShow(false);
        setFilter(false);
        setShowExportGridErrorAlert(true);
      }
    } else {
      setLoading(false);
      // setApiResNotOk(true);
      setModalShow(false);
      setFilter(false);
      setNoGridDataAlert(true)
    }
  };
  const handleModalShow = () => {
    setModalShow(true);
    // setDisable(true);
    setFilter(false);
    dispatch(setCheckBoxValue(false));
  };
  useEffect(() => {
    dispatch(setTDTypeAction(todate));
    // dispatch()
  }, []);
  // Showing Filter Slide Panel
  const [show, setShow] = useState(false);
  const handleSlidePanalClose = () => setShow(false);
  const handleSlidePanalShow = () => setShow(true);
  // Toggle Filter Slide Panel
  // const [toggleView, setToggleView] = useState(true);
  const { toggleView, setToggleView, chartMode, setChartMode } = useCombinedContext();
  const handleGridChartToggle = () => setToggleView(!toggleView);

  const handleExpandCollapse = (status: any) => {
    dispatch(setExpandCollapseAction(status));
  };
  const [isVisible, setIsVisible] = useState(true);
  const showStyle = { display: 'block' }; // or 'flex', 'grid', etc., as appropriate
  const hideStyle = { display: 'none' };
  const [powerBIKey, setPowerBIKey] = useState(Date.now());
  useEffect(() => {
    // Reset the key for PowerBI when needed to force remount
    if (!toggleView) {
      setPowerBIKey(Date.now());
    }
    // dispatch(setExpandCollapseAction("Collapse"));
    // dispatch(setExpandAllAction(false));
    
  }, [toggleView]);
  return (
    <>
      <DataGridControl
        handleShow={handleSlidePanalShow}
        handleModalShow={handleModalShow}
        handleGridChartToggle={handleGridChartToggle}
        handleExpandCollapse={handleExpandCollapse}
        setNoGridDataAlert={setNoGridDataAlert}
        setModalShow={setModalShow}
      />
      {/* {toggleView ? (
        // <DataGrid handlePanalClose={handleSlidePanalClose} todate={todate} />
        <GridComponent handlePanalClose={handleSlidePanalClose} todate={todate} />
      ) : (
        <PowerBI handlePanalClose={handleSlidePanalClose} todate={todate}/>
      )} */}
      <div style={{ display: toggleView ? 'block' : 'none' }}>
        <GridComponent handlePanalClose={handleSlidePanalClose} todate={todate}/* props */ />
      </div>
      <div style={{ display: !toggleView ? 'block' : 'none',height: '100%', width: '100%',}}>
        <div className="newrowpb">
        <PowerBI handlePanalClose={handleSlidePanalClose} todate={todate}/* props */ />
        </div>
        </div>
      <SlidePanel
        show={show}
        handleSlidePanalShow={handleSlidePanalShow}
        handleSlidePanalClose={handleSlidePanalClose}
      />
      <ExportGrid
        show={modalShow}
        exportToExcle={exportToExcle}
        handleModalHide={handleModalHide}
        handleCheckBox={handleCheckBox}
        filter={filter}
        // apiResNotOk={apiResNotOk}
        setFilter={setFilter}
        loading={loading}
        setLoading={setLoading}
      />
      {showExportGridErrorAlert && (
        <Modal show={true} size="sm">
          <ModalBody>
            <p>Error while exporting the data, please try again later!</p>
            <div>
              <button
                className="btn-sm btn btn-dark"
                onClick={() => setShowExportGridErrorAlert(false)}
              >
                Close
              </button>
            </div>
          </ModalBody>
        </Modal>
      )}
      {noGridDataAlert && (
        <Modal show={true} size="sm">
          <ModalBody>
            <p>Please apply filters to load data.</p>
            <div className="d-flex flex-row-reverse">
              <button
                className="btn-sm btn btn-dark"
                onClick={() => setNoGridDataAlert(false)}
              >
                Ok
              </button>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default Mtd;
