export const apiendpoints = {
  
  apiGetFilter: "/api/MLEdge/Getfilter",

  apiGetClass: "/api/MLEdge/GetChannelClass",

  apiSubClass: "/api/MLEdge/GetChannelSubClass",

  apiGroupClass: "/api/MLEdge/GetChannelGroupClass",

  apiLineItem: "/api/MLEdge/GetChannelLineItem",

  apiFinacialAccount: "/api/MLEdge/GetFinancialAccount",

  apiOrder: "/api/MLEdge/GetInternalOrder",

  apiProfitCenter: "/api/MLEdge/GetProfitCenter",

  apiCostCenter: "/api/MLEdge/GetCostCenter",

  apiOrg: "/api/MLEdge/GetChannelOrg",

  apiOrgSummary: "/api/MLEdge/GetChannelOrgSummary",

  apiGetCompanyRegion: "/api/MLEdge/GetCompanyRegion",

  apiGetSortOptions: "/api/MLEdge/GetSortOptions",

  apiGetSegmentalDataQTD: "/api/MLEdge/GetSegmentalDataQTD",

  apiMercuryRefreshTime: "/api/MLEdge/GetMercuryRefreshTime",

  apiGetPreference: "/api/MLEdge/Getpreferences",

  apiSavePreference: "/api/MLEdge/Savepreferences",

  apiDeletepreferences: "/api/MLEdge/Deletepreferences",

  apiExportToExcel: "/api/MLEdge/ExportToExcel",
  
  apiExportToPPT: "/api/MLEdge/ExportToPPT",

  apiSaveUserSettings: "/api/MLEdge/SaveUserSettings",

  apiGetUserSettings: "/api/MLEdge/GetUserSettings",

  apiGetPowerbiAccessToken:"/api/MLEdge/GetPowerbiAccessToken",

  TokenAPIPath: "/api/TokenAPI/GetAPIToken",

  tokenUserProfile: "/api/TokenAPI/GetAPIToken",

  graphApi: "https://graph.microsoft.com",

  apiMercuryAllCompanyCodeAccess: "/api/MLEdge/GetMercuryAllCompanyCodeAccess",
  
  apiGetDefaultPreferenceValues   :"/api/MLEdge/GetpreferencesForDefaultSelection",

  apiSaveUserSettingsMTD   :"/api/MLEdge/SaveUserSettingsMTD",

  apiSaveUserSettingsQTDnYTD   :"/api/MLEdge/SaveUserSettingsQTDnYTD",

  apiGetActualsClasses : "/api/MLEdge/GetActualsClasses",

  apiGetActualsClassesQtdYtd : "/api/MLEdge/GetActualsClassesForQTDYTD",

  apiGetSegmentalData : "/api/MLEdge/GetChilderenDataFromCache",

  apiGetAnomalyLink : "/api/MLEdge/GetAnomalyLink",

  apiSaveComment : "/api/MLEdge/SaveComment",

  apiGetPriorComments : "/api/MLEdge/GetPriorComments",

  apiCommentaryUserAccess : "/api/MLEdge/CommentaryUserAccess",
  apiCreateLogFile : "/api/MLEdge/CreateLogFile",
 apiAllSAPCompanyData:"/api/AccessManagement/GetAllSAPCompanyData",
  apiCommentAccess:"/api/AccessManagement/CommentAccess",
  apiCommentAccessUsers: "/api/AccessManagement/GetCommentAccessUsers",
  apiDeleteCommentAccessUsers: "/api/AccessManagement/DeleteCommentAccessUsers",
  apiSearchUsersInAD: "/api/AccessManagement/SearchUsersInAD",
  apiAddCommentUser: "/api/AccessManagement/AddCommentUser",

  // MCIO END POINTS STARTS HERE ----===================================////////////////////////

  apiMCIOGetAuthorization:"/api/MCIOAccessMangement/GetAuthorization",
  apiMCIOGetCommentAccessUsers:"/api/MCIOAccessMangement/GetCommentAccessUsers",
  apiMCIOAddCBSCommentUser:"/api/MCIOAccessMangement/AddCBSCommentUser",
  apiMCIODeleteCBSCommentUser:"/api/MCIOAccessMangement/DeleteCBSCommentUser"

  // apiMCIOGetAuthorization:"api/MCIOAccessMangement/GetAuthorization",



  // MCIO END POINTS ENDS HERE ----===================================////////////////////////

  }