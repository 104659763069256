import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import Layout from './Components/Layout';
 
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
 
//Pages
import Home from './Pages/Home';
import Trends from './Pages/Trends';
import Mtd from './Pages/Mtd';
import Qtd from './Pages/Qtd';
import Ytd from './Pages/Ytd';
import Analysis from './Pages/Analysis';
import ErrorPage from './Pages/ErrorPage';
import CubeAdmin from './Pages/CubeAdmin';
import Admin from './MCIO/Admin';
import CubeAccessDenied from './Pages/CubeAccessDenied';
// import PowerBI from './Pages/PowerBI';
 
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<Layout />}>
      <Route index element={<Home />}/>
      <Route path='trends' element={<Trends />}/>
      <Route path='mtd' element={<Mtd todate={"MTD"} />}/>
      <Route path='qtd-ytd' element={<Qtd />}/>
      <Route path='ytd' element={<Ytd />}/>
      {/* <Route path='powerbi' element = {<PowerBI />}/> */}
      <Route path='analysis' element={<Analysis />}/>
      <Route path='cubeadmin' element={<CubeAdmin />}/>
      <Route path='cubeaccessdenied' element={<CubeAccessDenied />}/>
      <Route path='mcioadmin' element={<Admin />}/>
      <Route path='*' element={<ErrorPage />}/>


    </Route>
  )
)
 
function App() {
  return (
    <RouterProvider router={router} />
  );
}
 
export default App;