import { useEffect, useState } from "react";
import FooterLogo from "../../Assests/Images/ms-logo.png";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Config from "../../Config";
import { setMercuryRefreshTimeVal } from "../../Redux/Actions/mercuryRefreshTimeAction";
import { getToken, getUsername } from "../Services/MsalService";
import { setCommentaryStatusAction } from "../../Redux/Actions/fieldSortAction";
import { RootReducer } from "../../Redux/Reducers/types";
import { useCombinedContext } from "../../Pages/PowerBIContext";


const Footer = ({ ...props }) => {

  // Show Mercury Refresh Time
  const showMercuryRefreshTimeText = props.showMercuryRefreshTime;
  const shownmfooterText = props.shownmfooterText;
  const _isFooterEnable = useSelector((state: RootReducer) => state.mcio.isFooterEnable);
 
  
  useEffect ( () => {
// alert(_isFooterEnable);
  },[_isFooterEnable])
  const setDarkMode = () => {
    document.querySelector("body")?.setAttribute("data-theme", "dark");
    localStorage.setItem("selectedTheme", "dark");
};

const setLightMode = () => {
    document.querySelector("body")?.setAttribute("data-theme", "light");
    localStorage.setItem("selectedTheme", "light");
};
  const selectedTheme = localStorage.getItem("selectedTheme");
  const [mercuryRefreshTime, setMercuryRefreshTime] = useState("");
  const dispatch = useDispatch();
  const { setthemeloader } = useCombinedContext();
   
  if (selectedTheme === "dark") {
    setDarkMode();
} else {
    setLightMode();
}

  const nmText = "nm = not meaningful (>200% or <-200%)";

  const getUserSettings = async () => {
    try {
      const userAlias = await getUsername();
        const response = await axios.get(
            `${Config.apiBaseAddress}${Config.apiGetUserSettings}?userAlias=${userAlias}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${await getToken()}`,
                },
            }
        );
        // console.log("Checking >>>", response);
        const responseData = response.data.isCommentaryOpt;
        if(responseData === true) {
        dispatch(await setCommentaryStatusAction(responseData));
        }
        const usertheme = response.data.theme;
        setthemeloader(usertheme);
        if(usertheme === 'light')
        {
          setLightMode();
        } else {
          setDarkMode();
        }
    } catch (error) {
        console.error("Failed to fetch user settings:", error);
    }
};
// useEffect(() => {
// alert(_mcio.isFooterEnable);
// },_mcio.isFooterEnable);


  useEffect(() => {
    (async () => {
      try {
        const responseMercury = await axios.get(`${Config.apiBaseAddress}${Config.apiMercuryRefreshTime}`, {
          headers: { "Authorization": `Bearer ${await getToken()}` }
        });
  
        const resultMercury = responseMercury.data;
        setMercuryRefreshTime(resultMercury);
  
        await getUserSettings();
      } catch (error) {
        console.error("Error fetching Mercury data:", error);
      }
    })();
  }, []);




  dispatch(setMercuryRefreshTimeVal(mercuryRefreshTime));

  return (
    <div className="footer">
      <div className="footer-logo">
        <img src={FooterLogo} alt="Footer Logo" />
      </div>

{(shownmfooterText && !_isFooterEnable) &&
      <div className="text-center">
        <span className="nm-text">{nmText}</span>
        {showMercuryRefreshTimeText &&
          <div className="last-refresh-date-time">
            <span className="fw-bold">Last Refresh Date/Time:</span>{" "}
            {mercuryRefreshTime || "Loading..."} <span className="">PST</span>
          </div>
        }
      </div>
}
{/* 
{_isFooterEnable && 
<div style={{display:'flex'}}>
  <div className="text-center Legend" style={{marginRight:'20px'}}>
       <span className="LegendTitle">Read</span>
          <div>
           <span className="LegendDesc"> Permission to read comments </span>
          </div>
      </div>
      <div className="text-center Legend">
      <span className="LegendTitle">Write</span>
         <div>
          <span className="LegendDesc"> Permission to add/edit comments and add/remove users </span>
         </div>
     </div>
     </div>
  
} */}
      <div className="privacy-text">
        Microsoft Confidential |{" "}
        <a
          target="_blank"
          href="https://privacy.microsoft.com/en-US/data-privacy-notice"
        >
          Privacy Notice
        </a>
      </div>
    </div>
  );
}

export default Footer;