import React, { useState, useEffect, useRef, RefObject } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { FaFilter, FaTimes } from "react-icons/fa";
import { CiFilter } from "react-icons/ci";
import { FaRegWindowClose } from "react-icons/fa";
import { AiOutlineDelete } from "react-icons/ai";
import { IoIosRefresh } from "react-icons/io";

import axios from 'axios';
import Config from '../Config';
import { getToken, getUsername } from  '../Components/Services/MsalService';
import Spinner from 'react-bootstrap/esm/Spinner';
import SpinnerComponent from '../Components/Common/SpinnerComponent';
import { Modal } from 'react-bootstrap';
import { LuAlertCircle } from "react-icons/lu";
import { FALSE } from 'sass';

import { getMcioUserdata, setMCIOData, setMCIOFooter } from '../Redux/Actions/mcioAction';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducer } from '../Redux/Reducers/types';



interface GridItem {
  id: string;
  name: string;
}



interface GridData {
  accessID: number;
  userName: string;
  userAlias: string;
  roleId:number;
  reportType:string;
}

interface Props {
  selectedOptions: GridItem[];
}

interface User {
  userAlias: string;
  userName: string;
  title: string;
}

interface GridComponentState {
  data: GridData[];
  gData: GridData[];
  pageData: GridData[];
  currentPage: number;
  searchTerm: string;
  showModal: boolean;
  totalPages: number;
  viewType: string;
  filterName: string;
  filterAlias: string;
  filterOptionName: string;
  filterOptionAlias: string;
  showFilterDropdownName: boolean;
  showFilterDropdownAlias: boolean;
  isFooterEnable:boolean;
}

const ITEMS_PER_PAGE = 7;

const filterOptions = [
  "contains",
  "is equal to",
  "is not equal to",
  "starts with",
  "does not contain",
  "ends with"
];

const McioAdminGrid: React.FC<Props> = (Props) => {


  const dispatch = useDispatch();

  const dropdownNameRef = useRef<HTMLDivElement>(null);
  const dropdownAliasRef = useRef<HTMLDivElement>(null);
 // const [selectedName, setSelectedName] = useState<string | null>(null);
  const [scrollbarWidth, setScrollbarWidth] = useState(0);
  const [hasScrollbar, setHasScrollbar] = useState(false);
  const [roleId, setRoleId] = useState(1);
  const gridContentRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [messageContent, setMessageContent] = useState('');
  const [fade, setFade] = useState(false);
  const [showheader , setshowheader] = useState(true);
  
  const reportType = "LEGALENTITY";
  const companyCode = Props.selectedOptions.map(option => option.id);

 // const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownNameRef.current &&
        !dropdownNameRef.current.contains(event.target as Node) &&
        dropdownAliasRef.current &&
        !dropdownAliasRef.current.contains(event.target as Node)
      ) {
        setState((prevState) => ({
          ...prevState,
          showFilterDropdownName: false,
          showFilterDropdownAlias: false,
        }));
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

 
  const [state, setState] = useState<GridComponentState>({
    data: [],
    gData: [],
    pageData: [],
    currentPage: 1,
    searchTerm: '',
    showModal: false,
    totalPages: 1,
    viewType: 'Read',
    filterName: '',
    filterAlias: '',
    filterOptionName: filterOptions[0],
    filterOptionAlias: filterOptions[0],
    showFilterDropdownName: false,
    showFilterDropdownAlias: false,
    isFooterEnable:false
  });

  const [searchResults, setSearchResults] = useState<User[]>([]); 
  const [tempsearchResults, settempSearchResults] = useState<User[]>([]); 

  const [selectedName, setSelectedName] = useState<string>('');
  const [selectedAlias, setSelectedAlias] = useState<string>('');
  const [isLoadingSearch , setIsLoadingSearch] = useState(false);
  const [isGridLoader , setIsGridLoader] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [itemIdToDelete, setItemIdToDelete] = useState("");

  const _McioData = useSelector((state: RootReducer) => state.mcio);


  useEffect( () => {
    //getCommentAccessUsers();
    GetGridData();
  }, []);

  useEffect (() => {
    console.log("Data ===>>"+_McioData);
    setState((prevState) => ({
      ...prevState,
      data:  _McioData.data 
    }));

    if( _McioData?.gData.length >   0 ){
    //setIsLoading(false);
    setIsGridLoader(false);
    applyFilters();
    }

  },[_McioData])

  const GetGridData = async () => {
    const _param = { 
      report: "MCIO"
      
    };
    dispatch(await getMcioUserdata(_param,state.viewType === 'Read' ? 1 : 2));
    setIsLoading(false);

  };
  useEffect(() => {
    applyFilters();
  }, [state.filterName, state.filterAlias, state.filterOptionName, state.filterOptionAlias, state.data, state.currentPage,state.viewType]);

  const applyFilters = () => {
    let filteredData = [...state.data]; // Use in-memory data

    if (state.filterName) {
      filteredData = filterData(filteredData, 'userName', state.filterName, state.filterOptionName);
    }

    if (state.filterAlias) {
      filteredData = filterData(filteredData, 'userAlias', state.filterAlias, state.filterOptionAlias);
    }

    // Paginate filtered data
    const totalPages = Math.ceil(filteredData.length / ITEMS_PER_PAGE);
    const pageData = filteredData.slice((state.currentPage - 1) * ITEMS_PER_PAGE, state.currentPage * ITEMS_PER_PAGE);

    setState((prevState) => ({
      ...prevState,
      pageData,
      totalPages: totalPages || 1,
    }));
  };

  const filterData = (data: GridData[], key: 'userName' | 'userAlias', value: string, option: string) => {
    switch (option) {
      case "is equal to":
        return data.filter(item => item[key].toLowerCase() === value.toLowerCase());
      case "is not equal to":
        return data.filter(item => item[key].toLowerCase() !== value.toLowerCase());       
      case "starts with":
        return data.filter(item => item[key].toLowerCase().startsWith(value.toLowerCase()));
      case "contains":
        return data.filter(item => item[key].toLowerCase().includes(value.toLowerCase()));
      case "does not contain":
        return data.filter(item => !item[key].toLowerCase().includes(value.toLowerCase()));
      case "ends with":
        return data.filter(item => item[key].toLowerCase().endsWith(value.toLowerCase()));
      default:
        return data;
    }
  };
  const openPopup = (id: string) => {
    setIsPopupOpen(true);
    setItemIdToDelete(id);
  };


const confirmDelete = async () => {
  if (itemIdToDelete !== null) {
    const deleteResult = await deleteCommentAccessUsers();
    console.log("delete result: ", deleteResult);

    if (deleteResult === 1) {
      setMessageContent("User has been deleted successfully.");
      setshowheader(false);
    } else if (deleteResult === 2) {
      setMessageContent("Can not delete write access user");
      setshowheader(true);
    } else if (deleteResult === 3) {
      setMessageContent("You do not have permission to delete the user.");
      setshowheader(true);
    }
    else if (deleteResult === 0) {
      setMessageContent("Admin users are protected from deletion.");
      setshowheader(true);
    }
    setShowMessageModal(true);  // Show the modal pop-up with the message
    GetGridData();
   // await getCommentAccessUsers();
  }

  setIsPopupOpen(false);  // Close the confirmation popup if any
};

const handleAddItem = async () => {
  try {
    const errorValue = await AddCommentAccessUsers();
    console.log("AddErrorValue:", errorValue);

    if (errorValue === "1") {
      GetGridData();
      setMessageContent("User has been added successfully.");
      setshowheader(false);
    } else if (errorValue === "3") {
      setMessageContent("The user is already present.");
      setshowheader(true);
    } else if (errorValue === "2") {
      setMessageContent("You do not have permission to add the user.");
      setshowheader(true);
    } else {
      setMessageContent("User cannot be added, because the user is already a superadmin.");
      setshowheader(true);
    }

    setShowMessageModal(true); 
    setSelectedName('');

    // Close the modal and clear selected valuesc

  } catch (error) {
    console.error("Error adding user:", error);
    setMessageContent("Failed to add user due to an unexpected error.");
    setshowheader(true);
    setShowMessageModal(true);  // Show the modal pop-up in case of error
  }
};

const deleteCommentAccessUsers = async () =>{
    try {

      const _p = state.data.filter( e => e.userAlias === itemIdToDelete);
      const token = await getToken();
      const response = await axios.post(
        `${Config.apiBaseAddress}${Config.apiMCIODeleteCBSCommentUser}`,_p[0],
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Delete string: ", response.data);
      let deleteValue = 0
      if (response.status === 200 && response.data) {
        deleteValue = response.data; 
        
      }
      console.log("delete value :", deleteValue)
      return deleteValue;
    } catch (error) {
      console.error("Failed to delete access user:", error);
      return 0;
    }
};

const AddCommentAccessUsers = async () => {
    try {
        const startTime = Date.now();
        const token = await getToken();
        console.log("Token retrieved in", Date.now() - startTime, "ms:", token);
        
        const response = await axios.post(
            `${Config.apiBaseAddress}${Config.apiMCIOAddCBSCommentUser}`,
            {},
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                params: {     
                    txtUserAlias: selectedAlias,
                    txtUserName: selectedName,
                    roleId: roleId, 
                    reportType: "MCIO"
                },
            }
        );
        console.log("API response time:", Date.now() - startTime, "ms");
        console.log("Add response string: ", response.data);

        let addValue = "0";
        if (response.status === 200 && response.data) {
            addValue = response.data; 
        }
        console.log("Add value:", addValue);
        return addValue.toString();
    } catch (error) {
        console.error("Failed to add access user:", error);
        return 0;
    }
};

const closePopup = () => {
    setIsPopupOpen(false);
  //  setItemIdToDelete("");
 };

const handlePageChange = (page: number) => {
  setState((prevState) => ({
    ...prevState,
    currentPage: page,
  }));
};

  const handleSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {

    const newSearchTerm = e.target.value;
    setState((prevState) => ({
      ...prevState,
      searchTerm: newSearchTerm,
    }));

    if(newSearchTerm == ''){
      setSearchResults([]);
      settempSearchResults([]);

    }
    const _recordinstate = tempsearchResults.filter( e => e.userName.toLowerCase().startsWith(newSearchTerm));
    if(_recordinstate.length > 0){

      setSearchResults(_recordinstate);
    }

    else{
    setIsLoadingSearch(true);

    // Call the search API
    if (newSearchTerm) {
      const results = await searchUsersinAD(newSearchTerm);
      setSearchResults(results);
      const combined = [...tempsearchResults, ...results];
      // Set the combined list to state

      settempSearchResults(combined);

      setIsLoadingSearch(false);
    } else {
      setSearchResults([]);
      settempSearchResults([]);
      setIsLoadingSearch(false); 
    }
  }
  };
  const searchUsersinAD = async (searchString: string): Promise<User[]> => {
    try {
      const token = await getToken(); 
      const response = await axios.get(
        `${Config.apiBaseAddress}${Config.apiSearchUsersInAD}`,
        {
          params: { searchString },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log('search response: ', response.data);

      if (response.status === 200 && response.data) {
        return Array.isArray(response.data)
          ? response.data.map((item: any) => ({
              userAlias: item.alias,
              userName: item.displayName,
              title: item.title, 
            }))
          : [];
      }

      return [];
    } catch (error) {
      console.error('Search failed:', error);
      return [];
    }
  };
  const handleSearchResultClick = (name: string , Alias: string) => {
    setSelectedName(name); 
    setSelectedAlias(Alias);
    setState((prevState) => ({
      ...prevState,
      searchTerm: name, // Update the search term to the selected name
    }));
    setSearchResults([]); // Optionally clear the search results after selection
  };
  const handleOpenModal = () => {
    setState((prevState) => ({
      ...prevState,
      showModal: true,
    }));
  };

  const handleCloseModal = () => {
    setState((prevState) => ({
      ...prevState,
      showModal: false,
      searchTerm: '',
    }));
  };
  const setViewType = async (viewType: string) => {

    const data = _McioData.gData;

    dispatch(await setMCIOData(data.filter((user: any) => user.roleID === (viewType === "Read" ? 1 : 2))))
    // const storageKey = viewType === "Read" ? "gridAdminData" : "gridUserData";
    // const  _d =  JSON.parse(localStorage.getItem(storageKey) || "[]");
    setState((prevState) => ({
      ...prevState,
      viewType,
      currentPage: 1,
    }));
    setRoleId(viewType === "Read" ? 1 : 2);
    //applyFilters();
  };

  const toggleDropdown = (type: 'name' | 'alias') => {
    if (type === 'name') {
      setState((prevState) => ({
        ...prevState,
        showFilterDropdownName: !prevState.showFilterDropdownName,
        showFilterDropdownAlias: false,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        showFilterDropdownAlias: !prevState.showFilterDropdownAlias,
        showFilterDropdownName: false,
      }));
    }
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>, type: 'name' | 'alias') => {
    if (type === 'name') {
      setState((prevState) => ({
        ...prevState,
        filterName: e.target.value,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        filterAlias: e.target.value,
      }));
    }
  };

  const handleClearFilter = (type: 'name' | 'alias') => {
    if (type === 'name') {
      setState((prevState) => ({
        ...prevState,
        filterName: '',
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        filterAlias: '',
      }));
    }
  };

  const handleFilterOptionChange = (option: string, type: 'name' | 'alias') => {
    if (type === 'name') {
      setState((prevState) => ({
        ...prevState,
        filterOptionName: option,
        showFilterDropdownName: false,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        filterOptionAlias: option,
        showFilterDropdownAlias: false,
      }));
    }
  };

  if (isLoading) {
    return (
    <div className='settingspinner'>
       <SpinnerComponent text="Loading..." />
    </div>
    );
  }
  
    console.log("pagedata : ", state.pageData);
 
    const sortedData = state.pageData ? [...state.pageData].sort((a, b) => a.userName.localeCompare(b.userName)) : [];
    console.log("chack@@@@",sortedData.length)
    return (
    <div>    
<div className='Grid-wrap'>
  <div className='grid-header'>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <label style={{ display: 'flex', alignItems: 'center', marginRight: '40%' }}>
        <input
          className='radio-check'
          type='radio'
          value='Read'
          checked={state.viewType === 'Read'}
          onChange={() => setViewType('Read')}
        />
        <span style={{ marginLeft: '8px', fontSize: '0.8em' }}>Read</span>
      </label>
      <label style={{ display: 'flex', alignItems: 'center' }}>
        <input
          className='radio-check'
          type='radio'
          value='Write'
          checked={state.viewType === 'Write'}
          onChange={() => setViewType('Write')}
        />
        <span style={{ marginLeft: '8px', fontSize: '0.8em' }}>Write</span>
      </label>
    </div>

    <button
      className='cube-add-button'
      onClick={() => {
        if (Props.selectedOptions.length <= 0) {
          setMessageContent('Please select SAP Company');
          setShowMessageModal(true);
          setshowheader(true);
        } else {
          handleOpenModal();
        }
      }}
      style={{ fontSize: '0.85em' }}
    >
      Add User
    </button>
  </div>

  <div className='grid-design2-mcio'>
    <div
      className='grid-header-row'
      style={{ marginRight: hasScrollbar ? scrollbarWidth : 0 }}
    >
      <div style={{ flex: 2, fontWeight: 'bold', borderRight: '2px solid var(--white-color)', minWidth: '150px', fontSize: '0.8em' }}>Name</div>
      <div style={{ flex: 2.01, fontWeight: 'bold', marginLeft: '8px', borderRight: '2px solid var(--white-color)', minWidth: '150px', fontSize: '0.8em' }}>Alias</div>
      <div style={{ flex: 0.475 }}></div>
    </div>

    <div className='grid-filter-row' style={{ marginRight: hasScrollbar ? scrollbarWidth : 0 }}>
      <div
        style={{
          flex: 2,
          position: 'relative',
          borderRight: '2px solid var(--white-color)',
          minWidth: '150px',
          display: 'flex',
          alignItems: 'center',
          fontSize: '0.8em'
        }}
        ref={dropdownNameRef}
      >
        <input
          type="text"
          className="input-filter"
          value={state.filterName}
          onChange={(e) => handleFilterChange(e, 'name')}
          placeholder="Filter by Name"
          style={{ flexGrow: 1 }}
        />
        <CiFilter
          size={20}
          style={{ cursor: 'pointer', marginLeft: '10px', position: 'relative',marginRight: '5px' }}
          onClick={() => toggleDropdown('name')}
        />
        {state.filterName && (
          <FaRegWindowClose
            style={{ cursor: 'pointer', marginLeft: '5px',marginRight: '5px' }}
            onClick={() => handleClearFilter('name')}
          />
        )}
        {state.showFilterDropdownName && (
          <div
            className='filter-icon-dropdown'
          >
            {filterOptions.map(option => (
              <div
                key={option}
                onClick={() => handleFilterOptionChange(option, 'name')}
                className={`dropdown-itemcube ${state.filterOptionName === option ? 'selected' : ''}`}
                style={{ padding: '5px', cursor: 'pointer' }}
              >
                {option}
              </div>
            ))}
          </div>
        )}
      </div>

      <div
        style={{
          flex: 2.01,
          position: 'relative',
          marginLeft: '8px',
          borderRight: '2px solid var(--white-color)',
          minWidth: '150px',
          display: 'flex',
          alignItems: 'center',
          fontSize: '0.8em'
        }}
        ref={dropdownAliasRef}
      >
        <input
          type="text"
          className="input-filter"
          value={state.filterAlias}
          onChange={(e) => handleFilterChange(e, 'alias')}
          placeholder="Filter by Alias"
          style={{ flexGrow: 1 }}
        />
        <CiFilter
          size={20}
          style={{ cursor: 'pointer', marginLeft: '10px', position: 'relative',marginRight: '5px' }}
          onClick={() => toggleDropdown('alias')}
        />
        {state.filterAlias && (
          <FaRegWindowClose
            style={{ cursor: 'pointer', marginLeft: '5px',marginRight: '5px' }}
            onClick={() => handleClearFilter('alias')}
          />
        )}
        {state.showFilterDropdownAlias && (
          <div
            className='filter-icon-dropdown'
          >
            {filterOptions.map(option => (
              <div
                key={option}
                onClick={() => handleFilterOptionChange(option, 'alias')}
                className={`dropdown-itemcube ${state.filterOptionAlias === option ? 'selected' : ''}`}
                style={{ padding: '5px', cursor: 'pointer',marginRight:'5px' }}
              >
                {option}
              </div>
            ))}
          </div>
        )}
      </div>

      <div style={{ flex: 0.475 }}></div>
    </div>

    {isGridLoader ? (
      <div className='AMgridspinner'>
        <SpinnerComponent text={'loading'} />
      </div>
    ) : (
      <>
        <div className='grid-content'>
          {(sortedData && sortedData.length > 0) ? (
            <Virtuoso
              style={{ overflow: 'hidden', fontSize: '0.8em' }}
              data={sortedData}
              itemContent={(index, item) => (
                <div
                  className={`grid-stripe ${(index % 2 === 0) ? 'even' : ''}`}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '10px',
                    minWidth: '300px'
                  }}
                >
                  <div style={{ flex: 2, borderRight: '2px solid var(--white-color)', padding: '0 10px', minWidth: '150px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                    {item.userName}
                  </div>
                  <div style={{ flex: 2, borderRight: '2px solid var(--white-color)', marginLeft: '8px', padding: '0 10px', minWidth: '150px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                    {item.userAlias}
                  </div>
                  <div style={{ flex: 0.5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <AiOutlineDelete
                      onClick={() => openPopup(item.userAlias)}
                      style={{ cursor: 'pointer' }}
                    />
                    {isPopupOpen && (
                      <Modal
                        show={isPopupOpen}
                        onHide={closePopup}
                        size="sm"
                        centered
                        backdrop="static"
                        backdropClassName="invisible-backdrop"
                      >
                        <Modal.Header closeButton style={{ border: '1px solid gray' }}>
                          <Modal.Title>
                            <LuAlertCircle style={{ marginRight: '5px' }} />
                            Warning
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ border: '1px solid gray' }}>
                          <div style={{ paddingBottom: '15px', paddingTop: '10px', fontSize: '0.85em'  }}>
                            Are you sure you want to delete this User?
                          </div>
                          <Modal.Footer>
                            <button className='popup-button' onClick={confirmDelete} style={{ fontSize: '0.8em' }}>
                              Ok
                            </button>
                            <button className='popup-button' onClick={closePopup} style={{ fontSize: '0.8em' }}>
                              Cancel
                            </button>
                          </Modal.Footer>
                        </Modal.Body>
                      </Modal>
                    )}
                  </div>
                </div>
              )}
            />
          ) : (
            <div style={{ textAlign: 'center', padding: '20px', color: 'var(--cube-text-color)', fontSize: '0.8em' }}>
              No records to display
            </div>
          )}
        </div>

        <Modal
          show={showMessageModal}
          onHide={() => setShowMessageModal(false)}
          size="sm"
          backdrop="static"
          centered
        >
          {showheader && (
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: '1.0em' }}>
              <LuAlertCircle style={{ marginRight: '5px' }} />
              Warning
            </Modal.Title>
          </Modal.Header>
          )}
          <Modal.Body style={{ fontSize: "0.85em" }}>
            <p>{messageContent}</p>
            <div className='button-design-alert'>
              <button className='alert-button' onClick={() => setShowMessageModal(false)} style={{ fontSize: '0.8em' }}>OK</button>
            </div>
          </Modal.Body>
        </Modal>

        <div className='grid-pagination' style={{ borderTop: (sortedData.length === 7) ? '0px' : '1px solid #ccc', fontSize: '0.8em' }}>
          <button
            onClick={() => handlePageChange(state.currentPage - 1)}
            disabled={state.currentPage === 1}
            style={{ margin: '0 5px' }}
          >
              &lt;
          </button>
          {Array.from({ length: state.totalPages }, (_, i) => (
            <button
              key={i}
              onClick={() => handlePageChange(i + 1)}
              style={{ margin: '0 5px', fontWeight: state.currentPage === i + 1 ? 'bold' : 'normal', backgroundColor: '#225b62', color: 'white' }}
            >
              {i + 1}
            </button>
          ))}
          <button
            onClick={() => handlePageChange(state.currentPage + 1)}
            disabled={state.currentPage === state.totalPages}
            style={{ margin: '0 5px' }}
          >
           &gt;
          </button>
        </div>
      </>
    )}
  </div>

  <div style={{ paddingTop: '40px', textAlign: 'center' }}>
    <p style={{
      fontSize: '0.8em',
      margin: '20px auto',
      display: 'inline-block',
      textAlign: 'left',
      color: 'var(--cube-text-color)'
    }}>
      <span style={{ display: 'inline-block', marginRight: '100px' }}>
        <strong>Read</strong><br />
        Permission to read comments
      </span>

      <span style={{ display: 'inline-block', marginLeft: '140px' }}>
        <strong>Write</strong><br />
        Permission to add/edit comments and add/remove users
      </span>
      <br />
    </p>
  </div>

  {state.showModal && (
 <Modal
 show={state.showModal}
 onHide={handleCloseModal}
 centered
 backdrop="static"
 dialogClassName="custom-medium-modal" // Custom class to control centering
>
 <Modal.Header closeVariant="white" closeButton>
   <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '1.0em' }}>
     Add user
   </Modal.Title>
 </Modal.Header>
 <Modal.Body>
   <div style={{ position: 'relative', width: '100%' }}>
     <input
       className="add-dropdown"
       type="text"
       value={state.searchTerm}
       onChange={handleSearch}
       placeholder="Search user name..."
       style={{
         paddingRight: '60px',
         width: '100%',
         height: '40px',
         border: '1px solid gray',
       }}
     />
     {isLoadingSearch && (
       <div
         style={{
           position: 'absolute',
           right: '40px',
           top: '50%',
           transform: 'translateY(-50%)',
         }}
       >
         <Spinner size="sm" />
       </div>
     )}
     {state.searchTerm && (
       <button
         style={{
           position: 'absolute',
           right: '10px',
           top: '50%',
           transform: 'translateY(-50%)',
           backgroundColor: 'transparent',
           border: 'none',
           fontSize: '1em',
           cursor: 'pointer',
           color: 'var(--text-color)'
         }}
         onClick={() => setState({ ...state, searchTerm: '' })}
       >
         &times;
       </button>
     )}
   </div>

   {!isLoadingSearch && state.searchTerm && searchResults.length > 0 && (
     <div className="search-results" style={{ fontSize: '0.8em' }}>
       {searchResults
         .filter((item) => item.userName.toLowerCase().includes(state.searchTerm.toLowerCase()))
         .map((item) => (
           <div
             key={item.userAlias}
             onClick={() => handleSearchResultClick(item.userName, item.userAlias)}
             style={{
               padding: '5px',
               cursor: 'pointer',
               backgroundColor: selectedName === item.userName ? '#d3d3d3' : 'transparent',
               transition: 'background-color 0.3s',
             }}
             onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = 'var(--cube-search-color)')}
             onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = selectedName === item.userName ? '#d3d3d3' : 'transparent')}
           >
             <div className="searchname">{item.userName}</div>
             <div className="searchtitle" style={{ color: 'var(--cube-searchresult-color)' }}>{item.title}</div>
           </div>
         ))}
     </div>
   )}

   <Modal.Footer>
     <button
       className="cube-add-button"
       onClick={() => {
         if (selectedName) {
           handleAddItem();
           handleCloseModal();
         } else {
          handleCloseModal();
           setMessageContent('Invalid input');
           setshowheader(true);
           setShowMessageModal(true);
         }
       }}
       style={{ fontSize: '0.8em' }}
     >
       Save
     </button>
   </Modal.Footer>
 </Modal.Body>
</Modal>


  )}
</div>

      </div>
  );
};

export default McioAdminGrid;