import Header from './Header/Header';
import Content from './Content';
import Footer from './Footer/Footer';
import { useEffect, useState } from "react";
import GlobalSpinner from './Common/GlobalSpinner';
import { getDefaultPrefenceValue } from '../Services/Service'
import { useDispatch, useSelector } from 'react-redux';
import { setDefaultFilterpreferncesAction } from '../Redux/Actions/preferencesDataActions';
import { RootReducer } from '../Redux/Reducers/types';
import { setExpandCollapseAction } from '../Redux/Actions/qtdDataActions';

import { useCombinedContext } from '../Pages/PowerBIContext';
import { useLocation } from 'react-router-dom';
function MainLayout() {

  // Default theme
  const onLoadSelectedTheme = localStorage.getItem("selectedTheme");
  document.querySelector("body")?.setAttribute("data-theme", onLoadSelectedTheme == null ? "light" : onLoadSelectedTheme);

  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const [Loading, setLoading] = useState<boolean>(false);
  const getpreferenceValues = async () => {
    setLoading(true);
    const response = await getDefaultPrefenceValue();
    console.log("ressss=>>>>", response);
    dispatch(setDefaultFilterpreferncesAction(response));
    setLoading(false);
  }

  const {toggleView ,  PowerBiloader , setPowerBiloader} = useCombinedContext();
  const commonStoreData = useSelector(
    (state: RootReducer) => state.commonStore
  );
  const location = useLocation();
  const isCubeAdmin = location.pathname.toLowerCase() === '/cubeadmin' || location.pathname.toLowerCase() === '/cubeaccessdenied';  useEffect(() => {
    // getpreferenceValues();
    dispatch(setExpandCollapseAction(""));
  }, []);

  useEffect(() => {
    //alert(commonStoreData.EnableSpinner);
    console.log("commonStoreData.EnableSpinner ==>", commonStoreData.EnableSpinner);
    setLoading(commonStoreData.EnableSpinner);
  }, [commonStoreData.EnableSpinner]);

  // useEffect(() => {
  //     if (window.performance) {
  //         if (performance.navigation.type == 1) 
  //         navigate('/');
  //         }
  //   }, []);

  if(Loading === true){
    setPowerBiloader(true);
  }
  return (
    <div className="App layout">
      <Header showAvtar={true} />
      <Content />

      <Footer showMercuryRefreshTime={!isCubeAdmin} shownmfooterText={!isCubeAdmin} />
      {Loading && toggleView === true && (
      <GlobalSpinner text="Loading" ></GlobalSpinner>
    )}
        {PowerBiloader && toggleView === false && (
      <div className="overlay"></div>
    )}
    </div>
  )
}

export default MainLayout