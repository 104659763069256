import React, { useState, useEffect } from "react";
import { ReactComponent as Sun } from "./Sun.svg";
import { ReactComponent as Moon } from "./Moon.svg";
import { savesetting } from "../../Services/SaveUserSetting";
import { useSelector } from "react-redux";
import { RootReducer } from "../../Redux/Reducers/types";
import { useCombinedContext } from "../../Pages/PowerBIContext";

type ThemeToggleProps = {
    onClick: (e:any) => void;
    disabled: boolean;
  };

  const ThemeToggle: React.FC<ThemeToggleProps> = ({ onClick , disabled }) => {

  const fieldSort = useSelector(
    (state: RootReducer) => state.fieldSort
  );
const { themeloader } = useCombinedContext();
    const setDarkMode = async () => {
        document.querySelector("body")?.setAttribute("data-theme", "dark");
        localStorage.setItem("selectedTheme", "dark");
        // await savesetting({ Commentarystatus: fieldSort.Commentarystatus, Theme: "dark" });
    };

    const setLightMode = async () => {
        document.querySelector("body")?.setAttribute("data-theme", "light");
        localStorage.setItem("selectedTheme", "light");
        // await savesetting({ Commentarystatus: fieldSort.Commentarystatus, Theme: "light" });
    };


    const selectedTheme = localStorage.getItem("selectedTheme");

    if (selectedTheme === "dark") {
        setDarkMode();
    } else {
        setLightMode();
    }

    useEffect(() => {
        if (selectedTheme === "dark") {
          setDarkMode();
        } else {
          setLightMode();
        }
      }, [selectedTheme]); 

    const toggleTheme = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setDarkMode();
        } else {
            setLightMode();
        }
    };

    return (
        <div className="theme-button">
            <input
                className="theme-button-input"
                type="checkbox"
                id="toggleMode"
                onChange={toggleTheme}
                defaultChecked={selectedTheme === "dark"}
                onClick={onClick}
                disabled = {disabled}
            />
            <label className="theme-button-label" htmlFor="toggleMode">
                <Sun />
                <Moon />
            </label>
        </div>
    );
};

export default ThemeToggle;