import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import BannerImg from '../Assests/Images/banner.png';
import { MdOutlineAccountBalanceWallet } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { initPreferencesAction } from '../Redux/Actions/preferencesDataActions';
import { getToken, getUsername } from '../Components/Services/MsalService';
import axios from 'axios';
import Config from '../Config';
import { setCommentaryStatusAction } from '../Redux/Actions/fieldSortAction';
import { useCombinedContext } from './PowerBIContext';


function Home() {
  const dispatch = useDispatch();
  // preferenceDataReducer
  const { toggleView, setToggleView, chartMode, setChartMode } = useCombinedContext();
  useEffect(() => {
    getUserSettings();
   // getCommentaryUserAccess();
    console.log("Hello Home")!
    dispatch(initPreferencesAction());
    setToggleView(true);
  },[])

  
  const setDarkMode = () => {
    document.querySelector("body")?.setAttribute("data-theme", "dark");
    localStorage.setItem("selectedTheme", "dark");
}; 
const setLightMode = () => {
    document.querySelector("body")?.setAttribute("data-theme", "light");
    localStorage.setItem("selectedTheme", "light");
};
const selectedTheme = localStorage.getItem("selectedTheme");
if (selectedTheme === "dark") {
  setDarkMode();
} else {
  setLightMode();
}

  
  const getUserSettings = async () => {
    try {
      const userAlias = await getUsername();
        const response = await axios.get(
            `${Config.apiBaseAddress}${Config.apiGetUserSettings}?userAlias=${userAlias}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${await getToken()}`,
                },
            }
        );
        // console.log("Checking >>>", response);
        const responseData = response.data.isCommentaryOpt;
        if(responseData === true) {
        dispatch(await setCommentaryStatusAction(responseData));
        }
        const usertheme = response.data.theme;
        if(usertheme === 'light')
        {
          setLightMode();
        } else {
          setDarkMode();
        }
    } catch (error) {
        console.error("Failed to fetch user settings:", error);
    }
};

// const getCommentaryUserAccess = async () => {
//   try {
//       const response = await axios.get(
//           `${Config.apiBaseAddress}${Config.apiCommentaryUserAccess}`,
//           {
//               headers: {
//                   "Content-Type": "application/json",
//                   Authorization: `Bearer ${await getToken()}`,
//               },
//           }
//       );
//       const responseData = response.data;
//       if(responseData === true) {
//       dispatch(await setCommentaryAccessAction(responseData));
//       }
     
//   } catch (error) {
//       console.error("Failed to fetch user access details:", error);
//   }
// };

  return (
    <div className="home">
      <img className='img-fluid mb-3' src={BannerImg} alt='Frame' />
      <NavLink className="mtd-link-btn" to="/mtd" ><MdOutlineAccountBalanceWallet />Subsidiary <br/> Profit &amp; Loss</NavLink>
      </div>
  )
}

export default Home