import { initialState } from "../../MCIO/Models";
import { SET_MCIO_FOOTER,GET_MCIO_USERDATA,ERROR_MCIO,SET_MCIO_DATA } from "../ActionContants/constants";
  



  const mcioReducer = (state = initialState, action: { type: any; payload: any }) => {
    switch (action.type) {
      case SET_MCIO_FOOTER:
        return {
          ...state,
          isFooterEnable: action.payload
        };
        case GET_MCIO_USERDATA:
          return {
            ...state,
            data: action.payload.data,
            gData  : action.payload.gData
          };
          case SET_MCIO_DATA:
            return{
              ...state,
              data: action.payload
            };
          case ERROR_MCIO:
            return state
        default:
        return state;
    }
  };
  
  export default mcioReducer;
  