import React, { useEffect, useState } from 'react';
import ThemeToggle from '../Components/ColorTheme/ThemeToggle';
import axios from 'axios';
import { getToken, getUsername } from '../Components/Services/MsalService';
import Config from '../Config';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducer } from '../Redux/Reducers/types';
import { setMCIOFooter } from '../Redux/Actions/mcioAction';
import McioAdminGrid from './McioAdminGrid';
import SpinnerComponent from '../Components/Common/SpinnerComponent';
import { useNavigate } from 'react-router-dom';
import { setCommentaryStatusAction } from '../Redux/Actions/fieldSortAction';

function Admin() {
  const fieldSort = useSelector((state: RootReducer) => state.fieldSort);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [theme, setTheme] = useState(localStorage.getItem('selectedTheme') || 'light');
  const [loading, setLoading] = useState(false);
  const [hasAdminAccess, setHasAdminAccess] = useState(false);
  const [loader, setLoader] = useState(false);
  const [themeLoaded, setThemeLoaded] = useState(false); // State to track theme loading
  const [Commentchk, setCommentchk] = useState(fieldSort.Commentarystatus);
  const [height, setHeight] = useState(window.innerHeight);
  const [selectedOptions, setSelectedOptions] = useState([
    { id: '-20', name: 'All - SAP Legal Entity' },
  ]);

  // Function to set Dark Mode
  const setDarkMode = () => {
    document.querySelector('body')?.setAttribute('data-theme', 'dark');
    localStorage.setItem('selectedTheme', 'dark');
    setTheme('dark');
  };

  // Function to set Light Mode
  const setLightMode = () => {
    document.querySelector('body')?.setAttribute('data-theme', 'light');
    localStorage.setItem('selectedTheme', 'light');
    setTheme('light');
  };

  // Fetch user settings (including theme preference)
  const getUserSettings = async () => {
    try {
      const userAlias = await getUsername();
      const response = await axios.get(
        `${Config.apiBaseAddress}${Config.apiGetUserSettings}?userAlias=${userAlias}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${await getToken()}`,
          },
        }
      );

      const responseData = response.data.isCommentaryOpt;
      if (responseData === true) {
        dispatch(await setCommentaryStatusAction(responseData));
      }

      const userTheme = response.data.theme;
      if (userTheme === 'dark') {
        setDarkMode();
      } else {
        setLightMode();
      }

      setThemeLoaded(true); // Set themeLoaded to true after fetching and setting the theme
    } catch (error) {
      console.error('Failed to fetch user settings:', error);
      setThemeLoaded(true); // Even if there's an error, set themeLoaded to true to stop loading
    }
  };

  // Check access rights
  const checkAccess = async () => {
    setIsLoading(true);
    const access = await getCommentAccess();
    setHasAdminAccess(access);
    setIsLoading(false);
    if (!access) {
      navigate('/CubeAccessDenied');
    }
  };

  // Fetch comment access
  const getCommentAccess = async () => {
    try {
      const response: any = await axios.get(
        `${Config.apiBaseAddress}${Config.apiMCIOGetAuthorization}`,
        {
          headers: { Authorization: `Bearer ${await getToken()}` },
        }
      );
      return response.status === 200 && response.data;
    } catch (error) {
      console.error('Failed to fetch comment access details:', error);
      return false;
    }
  };

  // Save user settings
  const savesetting = async (savePreference: any) => {
    try {
      savePreference.IsCommentaryOpt = savePreference.Commentarystatus;
      savePreference.userAlias = await getUsername();
      const response = await axios.post(
        `${Config.apiBaseAddress}${Config.apiSaveUserSettings}`,
        JSON.stringify(savePreference),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${await getToken()}`,
          },
        }
      );
      if (response.status === 200) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error('Failed to fetch classes:', error);
    }
  };

  // Handle theme toggle
  const handleClick = async (e: any) => {
    setLoading(true);
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);

    const savePreference = {
      Commentarystatus: Commentchk,
      Theme: newTheme,
    };
    await savesetting(savePreference);
  };

  useEffect(() => {
    dispatch(setMCIOFooter(true));
    getUserSettings(); // Fetch user settings and theme
    checkAccess(); // Check for admin access rights

    return () => {
      dispatch(setMCIOFooter(false));
    };
  }, []);

  useEffect(() => {
    // Update window height dynamically
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  const updateHeight = () => {
    // Set the height for any dynamic adjustments
    setHeight(window.innerHeight);
  };

  if (!themeLoaded) {
    // Show loading spinner until theme is loaded
    return (
      <div className="settingspinner">
        <SpinnerComponent text="Loading..." />
      </div>
    );
  }

  return hasAdminAccess ? (
    <div className="Grid-wrapp">
      <div className="cube-scroll-container">
        <div className="cube-container">
          <span className="cube-title" style={{ padding: '2px 0px 0px 4px', fontSize: '16pt', fontWeight: 'normal!important' }}>
            MSIO Assets and Liabilities Analysis Commentary Permissions
          </span>
          <span className="cube-theme-wrap">
            <span>Light</span>
            <ThemeToggle onClick={handleClick} disabled={loading} />
            <span>Dark</span>
          </span>
        </div>

        {/* Display the loading spinner if loader is true */}
        {loading && (
          <div className="overlayloader">
            <SpinnerComponent text="Loading..." />
          </div>
        )}

        <div>
          <McioAdminGrid selectedOptions={selectedOptions} />
        </div>
      </div>

      {loading && <div className="overlay"></div>}
    </div>
  ) : null;
}

export default Admin;
