  
export interface User {
    userAlias: string;
    userName: string;
    title: string;
  }
  export interface GridData {
    accessID: number;
    userName: string;
    userAlias: string;
    roleId:number;
  }
  export interface McioState {
    data: GridData[];
    gData: GridData[];
    pageData: GridData[];
    currentPage: number;
    searchTerm: string;
    showModal: boolean;
    totalPages: number;
    viewType: string;
    filterName: string;
    filterAlias: string;
    filterOptionName: string;
    filterOptionAlias: string;
    showFilterDropdownName: boolean;
    showFilterDropdownAlias: boolean;
    isFooterEnable:boolean;
  
  }

  export const  initialState: McioState ={
    data: [],
    gData:[],
    pageData: [],
    currentPage: 1,
    searchTerm: '',
    showModal: false,
    totalPages: 1,
    viewType: 'Read',
    filterName: '',
    filterAlias: '',
    filterOptionName:  "contains",
    filterOptionAlias: "contains",
    showFilterDropdownName: false,
    showFilterDropdownAlias: false,
    isFooterEnable:false
  }
  
