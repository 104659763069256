import React from "react";
import { ContactusUrl } from "../Config";

function CubeAccessDenied(){
    return (
        <div style={{
            height: "50vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
        }}>
            <p> 
                <span style={{
                fontSize: "xxx-large",
                color:"var(--cube-searchresult-color)"
            }} >:-( </span>
                <br /> <span style={{
                fontSize: "xxx-large",
                color:"var(--cube-searchresult-color)"
            }}>You are not authorised to view this page</span>
            <br/> <span style={{
                marginRight:"8px"
            }}>
                Please contact 
            </span>
            <a 
            href={ContactusUrl} target="_blank" style={{
                cursor:'pointer',
                textDecoration:'none'
            }}
            onMouseEnter={(e) => (e.target as HTMLAnchorElement).style.textDecoration = 'underline'}
           onMouseLeave={(e) => (e.target as HTMLAnchorElement).style.textDecoration = 'none'}
  > FinsUp Support</a>
            </p>
        </div>
    );
}

export default CubeAccessDenied;