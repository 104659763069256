import { apiendpoints } from "./api-endpoints";


export const UserguideUrl = "https://microsoft.sharepoint.com/:fl:/s/c277c028-a75e-42fa-948c-cb401af889f3/ERDU8z8cH0lKp4vrB-uIfgsBRcO0QsBN33LZhfH2d0LM0w?e=XCzSk4&nav=cz0lMkZzaXRlcyUyRmMyNzdjMDI4LWE3NWUtNDJmYS05NDhjLWNiNDAxYWY4ODlmMyZkPWIlMjE5UF9uckpPTHRrNkRBclB6SnJXeEdqRlVpUlJ5YnJaQWhHRHpzQVRrbGs4dlBHeXIzNlhDVExjcW14QXd0b0NBJmY9MDFLTFBSMlhBUTJUWlQ2SEE3SkZGS1BDN0xBN1ZZUTdRTCZjPSUyRiZhPUxvb3BBcHAmcD0lNDBmbHVpZHglMkZsb29wLXBhZ2UtY29udGFpbmVyJng9JTdCJTIydyUyMiUzQSUyMlQwUlRVSHh0YVdOeWIzTnZablF1YzJoaGNtVndiMmx1ZEM1amIyMThZaUU1VUY5dWNrcFBUSFJyTmtSQmNsQjZTbkpYZUVkcVJsVnBVbEo1WW5KYVFXaEhSSHB6UVZScmJHczRkbEJIZVhJek5saERWRXhqY1cxNFFYZDBiME5CZkRBeFMweFFVakpZUjFSUFEwSTBVbG8wU1ROYVNFcEhURFZLU2tFek5WbFJXbG8lM0QlMjIlMkMlMjJpJTIyJTNBJTIyZjUyMDc1NTctZmJkMC00YjU0LTk5ZTYtODg4ZjUwYTE0OGFlJTIyJTdE"
export const ContactusUrl = "https://onefinance.microsoftcrmportals.com/finsuphome/";

//export const ContactusUrl = "mailto:FBISMS@microsoft.com?subject=Support%20query%20for%20FRAME";

export const TrainingUrl = "https://loop.microsoft.com/p/eyJ3Ijp7InUiOiJodHRwczovL21pY3Jvc29mdC5zaGFyZXBvaW50LmNvbS8%2fbmF2PWN6MGxNa1ltWkQxaUlUbFFYMjV5U2s5TWRHczJSRUZ5VUhwS2NsZDRSMnBHVldsU1VubGljbHBCYUVkRWVuTkJWR3RzYXpoMlVFZDVjak0yV0VOVVRHTnhiWGhCZDNSdlEwRW1aajB3TVV0TVVGSXlXRWRVVDBOQ05GSmFORWt6V2toS1IwdzFTa3BCTXpWWlVWcGFKbU05Sm1ac2RXbGtQVEUlM0QiLCJyIjpmYWxzZX0sInAiOnsidSI6Imh0dHBzOi8vbWljcm9zb2Z0LnNoYXJlcG9pbnQuY29tLzpmbDovci9zaXRlcy9jMjc3YzAyOC1hNzVlLTQyZmEtOTQ4Yy1jYjQwMWFmODg5ZjMvU2hhcmVkJTIwRG9jdW1lbnRzL0xvb3BBcHBEYXRhL1VudGl0bGVkJTIwcGFnZSUyMDEubG9vcD9kPXdmOWY2NzdkZDU2NmE0NDhiODEzZTY3MzY4YzUwNWE5YiZjc2Y9MSZ3ZWI9MSZuYXY9Y3owbE1rWnphWFJsY3lVeVJtTXlOemRqTURJNExXRTNOV1V0TkRKbVlTMDVORGhqTFdOaU5EQXhZV1k0T0RsbU15WmtQV0loT1ZCZmJuSktUMHgwYXpaRVFYSlFla3B5VjNoSGFrWlZhVkpTZVdKeVdrRm9SMFI2YzBGVWEyeHJPSFpRUjNseU16WllRMVJNWTNGdGVFRjNkRzlEUVNabVBUQXhTMHhRVWpKWVJ6VlBOek5RVXpKVFYxSk9RMGxEVUZSSVJ6SkhSa0ZYVlRNbVl6MGxNa1ltWm14MWFXUTlNU1poUFV4dmIzQkJjSEFtY0QwbE5EQm1iSFZwWkhnbE1rWnNiMjl3TFhCaFoyVXRZMjl1ZEdGcGJtVnlKbmc5SlRkQ0pUSXlkeVV5TWlVelFTVXlNbFF3VWxSVlNIaDBZVmRPZVdJelRuWmFibEYxWXpKb2FHTnRWbmRpTW14MVpFTTFhbUl5TVRoWmFVVTFWVVk1ZFdOcmNGQlVTRkp5VG10U1FtTnNRalpUYmtwWVpVVmtjVkpzVm5CVmJFbzFXVzVLWVZGWGFFaFNTSEI2VVZaU2NtSkhjelJrYkVKSVpWaEplazVzYUVSV1JYaHFZMWN4TkZGWVpEQmlNRTVDWmtSQmVGTXdlRkZWYWtwWlVqRlNVRkV3U1RCVmJHOHdVMVJPWVZORmNFaFVSRlpMVTJ0RmVrNVdiRkpYYkc4bE0wUWxNaklsTWtNbE1qSnBKVEl5SlROQkpUSXlaalV5TURjMU5UY3RabUprTUMwMFlqVTBMVGs1WlRZdE9EZzRaalV3WVRFME9HUmlKVEl5SlRkRSIsInIiOmZhbHNlfSwiaSI6eyJpIjoiZjUyMDc1NTctZmJkMC00YjU0LTk5ZTYtODg4ZjUwYTE0OGRiIn19"

// export const CommentUrl ="https://ifrauat.azurewebsites.net/IFRACubeAdministration";

const dev = {
  apiBaseAddress: "https://localhost:44305",
  // apiBaseAddressSecondary:"https://localhost:44306",
   redirectUri: "http://localhost:3000",
   ClientID:"6c1cee6f-6f00-4c4b-88ce-bf7d7e3f623f",
   ClientScope:"api://6c1cee6f-6f00-4c4b-88ce-bf7d7e3f623f/user_impersonation",
   CommentUrl : "https://ifrauat.azurewebsites.net/IFRACubeAdministration/",
   MercuryAccessUrl : "https://euaaccessportalint.azurewebsites.net/request/access/Mercury",
   ReportIDMTD: "38e38fcf-dcff-488a-b40d-e9ca1f9f900b",
   ReportIDQTDYTD: "bfa7dd6d-627d-41c7-ad69-7b7a8ad3949f",
   PowerBIMTDURL: "https://msit.powerbi.com/reportEmbed?reportId=38e38fcf-dcff-488a-b40d-e9ca1f9f900b&groupId=702c96b8-dc0e-4c30-807f-3e4fc8542d87&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9ERi1NU0lULVNDVVMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJlbWJlZEZlYXR1cmVzIjp7InVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJkaXNhYmxlQW5ndWxhckpTQm9vdHN0cmFwUmVwb3J0RW1iZWQiOnRydWV9fQ%3d%3d",
   PowerBIQTDYTDURL: "https://msit.powerbi.com/reportEmbed?reportId=bfa7dd6d-627d-41c7-ad69-7b7a8ad3949f&groupId=702c96b8-dc0e-4c30-807f-3e4fc8542d87&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9ERi1NU0lULVNDVVMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJlbWJlZEZlYXR1cmVzIjp7InVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJkaXNhYmxlQW5ndWxhckpTQm9vdHN0cmFwUmVwb3J0RW1iZWQiOnRydWV9fQ%3d%3d",
   Headcount: "https://msit.powerbi.com/groups/9961c3dd-4798-463b-bdbb-d1a99b219e65/reports/83dbe0bf-b602-4a16-8a88-e3ec48edfa0a/dd08d05a9919b13d7c52?experience=power-bi",
   ...apiendpoints
   

};

const release = {
  redirectUri : "https://framedev.azurewebsites.net",
  apiBaseAddress: "https://frameapidev.azurewebsites.net",
  ClientID:"6c1cee6f-6f00-4c4b-88ce-bf7d7e3f623f",
  ClientScope:"api://6c1cee6f-6f00-4c4b-88ce-bf7d7e3f623f/user_impersonation",
  CommentUrl : "https://ifrauat.azurewebsites.net/IFRACubeAdministration/",
  MercuryAccessUrl : "https://euaaccessportalint.azurewebsites.net/request/access/Mercury",
  ReportIDMTD: "38e38fcf-dcff-488a-b40d-e9ca1f9f900b",
  ReportIDQTDYTD: "bfa7dd6d-627d-41c7-ad69-7b7a8ad3949f",
  PowerBIMTDURL: "https://msit.powerbi.com/reportEmbed?reportId=38e38fcf-dcff-488a-b40d-e9ca1f9f900b&groupId=702c96b8-dc0e-4c30-807f-3e4fc8542d87&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9ERi1NU0lULVNDVVMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJlbWJlZEZlYXR1cmVzIjp7InVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJkaXNhYmxlQW5ndWxhckpTQm9vdHN0cmFwUmVwb3J0RW1iZWQiOnRydWV9fQ%3d%3d",
  PowerBIQTDYTDURL: "https://msit.powerbi.com/reportEmbed?reportId=bfa7dd6d-627d-41c7-ad69-7b7a8ad3949f&groupId=702c96b8-dc0e-4c30-807f-3e4fc8542d87&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9ERi1NU0lULVNDVVMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJlbWJlZEZlYXR1cmVzIjp7InVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJkaXNhYmxlQW5ndWxhckpTQm9vdHN0cmFwUmVwb3J0RW1iZWQiOnRydWV9fQ%3d%3d",
  Headcount: "https://msit.powerbi.com/groups/9961c3dd-4798-463b-bdbb-d1a99b219e65/reports/83dbe0bf-b602-4a16-8a88-e3ec48edfa0a/dd08d05a9919b13d7c52?experience=power-bi",
  ...apiendpoints
};

const uat = {
 redirectUri : "https://frameuat.azurewebsites.net",
 apiBaseAddress: "https://frameapiuat.azurewebsites.net",
 ClientID:"c3a882a8-e0a4-4e53-abb9-a87c6aad6ec1",
 ClientScope:"api://c3a882a8-e0a4-4e53-abb9-a87c6aad6ec1/user_impersonation",
 CommentUrl: "https://ifrauat.azurewebsites.net/IFRACubeAdministration/",
 MercuryAccessUrl : "https://euaaccessportalint.azurewebsites.net/request/access/Mercury",
 ReportIDMTD: "38e38fcf-dcff-488a-b40d-e9ca1f9f900b",
 ReportIDQTDYTD: "bfa7dd6d-627d-41c7-ad69-7b7a8ad3949f",
 PowerBIMTDURL: "https://msit.powerbi.com/reportEmbed?reportId=38e38fcf-dcff-488a-b40d-e9ca1f9f900b&groupId=702c96b8-dc0e-4c30-807f-3e4fc8542d87&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9ERi1NU0lULVNDVVMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJlbWJlZEZlYXR1cmVzIjp7InVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJkaXNhYmxlQW5ndWxhckpTQm9vdHN0cmFwUmVwb3J0RW1iZWQiOnRydWV9fQ%3d%3d",
 PowerBIQTDYTDURL: "https://msit.powerbi.com/reportEmbed?reportId=bfa7dd6d-627d-41c7-ad69-7b7a8ad3949f&groupId=702c96b8-dc0e-4c30-807f-3e4fc8542d87&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9ERi1NU0lULVNDVVMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJlbWJlZEZlYXR1cmVzIjp7InVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJkaXNhYmxlQW5ndWxhckpTQm9vdHN0cmFwUmVwb3J0RW1iZWQiOnRydWV9fQ%3d%3d",
 Headcount: "https://msit.powerbi.com/groups/702c96b8-dc0e-4c30-807f-3e4fc8542d87/reports/890fb232-4910-4425-bfd3-d4fc375dbd23/dd08d05a9919b13d7c52?experience=power-bi",
 ...apiendpoints
};
const prod = {
  redirectUri : "https://frame-prod.azurewebsites.net",
  apiBaseAddress: "https://frameapiprod.azurewebsites.net",
  ClientID:"9b8a4aeb-4faf-4fcb-8f93-e7c2ddb59e6a",
  ClientScope:"api://9b8a4aeb-4faf-4fcb-8f93-e7c2ddb59e6a/user_impersonation",
  CommentUrl: "https://ifra.azurewebsites.net/IFRACubeAdministration",
  MercuryAccessUrl : "https://euaaccessportal.microsoft.com/request/access/Mercury",
  ReportIDMTD: "38e38fcf-dcff-488a-b40d-e9ca1f9f900b",
  ReportIDQTDYTD: "bfa7dd6d-627d-41c7-ad69-7b7a8ad3949f",
  PowerBIMTDURL: "https://msit.powerbi.com/reportEmbed?reportId=38e38fcf-dcff-488a-b40d-e9ca1f9f900b&groupId=702c96b8-dc0e-4c30-807f-3e4fc8542d87&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9ERi1NU0lULVNDVVMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJlbWJlZEZlYXR1cmVzIjp7InVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJkaXNhYmxlQW5ndWxhckpTQm9vdHN0cmFwUmVwb3J0RW1iZWQiOnRydWV9fQ%3d%3d",
  PowerBIQTDYTDURL: "https://msit.powerbi.com/reportEmbed?reportId=bfa7dd6d-627d-41c7-ad69-7b7a8ad3949f&groupId=702c96b8-dc0e-4c30-807f-3e4fc8542d87&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9ERi1NU0lULVNDVVMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJlbWJlZEZlYXR1cmVzIjp7InVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJkaXNhYmxlQW5ndWxhckpTQm9vdHN0cmFwUmVwb3J0RW1iZWQiOnRydWV9fQ%3d%3d",
  Headcount: "https://msit.powerbi.com/groups/9961c3dd-4798-463b-bdbb-d1a99b219e65/reports/83dbe0bf-b602-4a16-8a88-e3ec48edfa0a/dd08d05a9919b13d7c52?experience=power-bi",
  ...apiendpoints
 };
let Config: any;

switch (process.env.REACT_APP_ENVIRONMENT) {
  case "Development":
    Config = dev;
    break;
  case "Release":
    Config = release;
    break;
    case "UAT":
      Config = uat;
      break;
      case "Production":
      Config = prod;
      break;
  default:
    Config = dev;
    break;
}

export default Config;
